import Box from "@mui/material/Box";
import {
  Grid,
  Typography,
  Stack,
  Divider,
  CircularProgress,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PreviewAttachmentDialog from "../PreviewAttachmentDialog";
import React, { useState, useEffect } from "react";
import { convertTimeFormat } from "../../utils/timeFormatter";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router";
import axios from "axios";
import GeneralWithCategoriesForm from "./generalWithCategoriesForm";
import ElectricityForm from "./electricityForm";
import WaterForm from "./waterForm";
import NewCraftForm from "./newCraftForm";
import ClearanceCertificateForm from "./clearanceCertificateForm";
import { filePathToFile } from "../../utils/pathToFileObject";

const OrderDetailsForm = () => {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [generalServices, setGeneralServices] = useState({});
  const [craftsData, setCraftsData] = useState({});
  const [clearanceCertificateData, setClearanceCertificateData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAttachmentsLoading, setIsAttachmentsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [attachmentDetails, setAttachmentsDetails] = useState(undefined);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        setIsLoading(true);
        const orderDetailsResponse = await axios.get(`/Application/${id}`);
        const orderDetailsData = orderDetailsResponse;
        setOrderDetails(orderDetailsData);

        const fragmentName = orderDetailsData.fragmentName
          ?.trim()
          .toLowerCase();

        if (
          ["general", "generalcategories", "electricity", "water"].includes(
            fragmentName
          )
        ) {
          const generalServicesResponse = await axios.get(
            `/Application/${id}/GeneralServices`
          );
          setGeneralServices(generalServicesResponse);
        }

        if (fragmentName === "newcrafts") {
          const craftsDataResponse = await axios.get(
            `/Application/${id}/Craft`
          );
          setCraftsData(craftsDataResponse);
        }

        if (fragmentName === "clearancecertificate") {
          const clearanceCertificateDataResponse = await axios.get(
            `/Application/${id}/ClearanceCertificate`
          );
          setClearanceCertificateData(clearanceCertificateDataResponse);
        }

        setIsLoading(false);
      } catch (e) {
        console.log("Error fetching order details", e);
        setIsLoading(false);
      }
    };

    const fetchAttachments = async () => {
      try {
        setIsAttachmentsLoading(true);
        const attachmentsResponse = await axios.get(
          `/Application/${id}/Attachments`
        );
        setAttachments(attachmentsResponse);
        setIsAttachmentsLoading(false);
      } catch (e) {
        console.log("Error fetching attachments", e);
        setIsAttachmentsLoading(false);
      }
    };

    // Call both functions simultaneously
    fetchOrderDetails();
    fetchAttachments();
  }, [id]);

  const showDialog = async (data) => {
    try {
      const fileObject = await filePathToFile(data.path, data.fileName);
      setAttachmentsDetails({ fileObject });
      setOpen(true);
    } catch (e) {}
  };

  const hideDialog = () => {
    setOpen(false);
    setAttachmentsDetails(undefined);
  };

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress size={80} />
      </Box>
    );
  }

  // Ensure orderDetails is defined before accessing its properties
  if (!orderDetails) {
    return null; // or show a fallback UI if needed
  }

  const isGeneralForm = orderDetails.fragmentName === "General";
  const isGeneralCategoryForm =
    orderDetails.fragmentName === "GeneralCategories";
  const isElectricityForm = orderDetails.fragmentName === "Electricity";
  const isWaterForm = orderDetails.fragmentName === "Water";
  const isNewCraft = orderDetails.fragmentName === "NewCrafts";
  const isClearanceCertificate =
    orderDetails.fragmentName === "ClearanceCertificate";

  return (
    <Stack gap={2}>
      <Box
        sx={{
          background: "#fff",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
          width: isMobile ? "unset" : 300,
        }}
      >
        <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
          معلومات الطلب
        </Typography>
      </Box>
      <Grid
        container
        sx={{ backgroundColor: "#fff", mt: 2, p: 2, width: "100%" }}
        spacing={isMobile ? 3 : 0}
      >
        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}> نوع الطلب: </Typography>
            <Typography>{orderDetails.serviceType?.name}</Typography>
          </Stack>
        </Grid>

        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}> رقم الطلب:</Typography>
            <Typography>{orderDetails.subscriberServiceFullNumber}</Typography>
          </Stack>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}> تاريخ الطلب:</Typography>
            <Typography sx={{ direction: "ltr" }}>
              {convertTimeFormat(orderDetails.insertDate)}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Box
        sx={{
          background: "#fff",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
          width: isMobile ? "unset" : 300,
        }}
      >
        <Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">
          بيانات مقدم الطلب
        </Typography>
      </Box>
      <Grid
        container
        sx={{ backgroundColor: "#fff", mt: 2, p: 2, width: "100%" }}
        spacing={3}
      >
        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>
              {" "}
              اسم مقدم الطلب:
            </Typography>
            <Typography>{orderDetails.applicant?.fullName}</Typography>
          </Stack>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}> نوع الوثيقة:</Typography>
            <Typography>
              {orderDetails.applicant?.documentType?.name}
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}> رقم الوثيفة:</Typography>
            <Typography> {orderDetails.applicant?.documentNumber}</Typography>
          </Stack>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}> رقم الموبايل:</Typography>
            <Typography> {orderDetails.applicant?.phone1}</Typography>
          </Stack>
        </Grid>
        <Grid item md={6} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>
              {" "}
              البريد الالكتروني:
            </Typography>
            <Typography
              title={orderDetails.applicant?.email}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {" "}
              {orderDetails.applicant?.email}
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={6} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}> العنوان:</Typography>
            <Typography>{`${orderDetails.applicant?.country?.name} - ${orderDetails.applicant?.city}`}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid item md={12} xs={12}>
        <Divider textAlign="left">
          <Typography sx={{ fontWeight: "bold" }}>قدم بواسطة</Typography>
        </Divider>
      </Grid>

      <Grid
        container
        sx={{ backgroundColor: "#fff", mt: 2, p: 2, width: "100%" }}
        spacing={isMobile ? 3 : 0}
      >
        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}> قدم بواسطة:</Typography>
            <Typography>{orderDetails.appliedBy} </Typography>
          </Stack>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}> رقم الوثيقة:</Typography>
            <Typography> {orderDetails.appliedByDocumentNumber}</Typography>
          </Stack>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>الصفة:</Typography>
            <Typography>{orderDetails.appliedByRelation} </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid item md={12} xs={12}>
        <Divider />
      </Grid>
      <Box
        sx={{
          background: "#fff",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
          width: isMobile ? "unset" : 300,
        }}
      >
        <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
          عنوان الطلب
        </Typography>
      </Box>
      <Grid
        container
        sx={{ backgroundColor: "#fff", mt: 2, p: 2, width: "100%" }}
        spacing={3}
      >
        <Grid item md={6} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>
              {" "}
              الغاية من تقديم الطلب :
            </Typography>
            <Typography> {orderDetails.serviceGoal}</Typography>
          </Stack>
        </Grid>
        <Grid item md={6} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>
              {" "}
              ملاحظات المراجعة :
            </Typography>
            <Typography>{orderDetails.serviceReviewNote}</Typography>
          </Stack>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}> الموقع:</Typography>
            <Typography> {orderDetails?.location}</Typography>
          </Stack>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>نوع المستفيد :</Typography>
            <Typography> {orderDetails?.beneficiaryType?.name}</Typography>
          </Stack>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>رمز الاشتراك :</Typography>
            <Typography> {orderDetails?.citizenService?.code}</Typography>
          </Stack>
        </Grid>
      </Grid>

      <Grid item md={12} xs={12}>
        <Divider />
      </Grid>
      <Box
        sx={{
          background: "#fff",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
          width: isMobile ? "unset" : 300,
        }}
      >
        <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
          {" "}
          مسار الطلب
        </Typography>
      </Box>
      <Grid
        container
        sx={{ backgroundColor: "#fff", mt: 2, p: 2, width: "100%" }}
        spacing={isMobile ? 3 : 0}
      >
        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}> النشاط الحالي:</Typography>
            <Typography>{orderDetails.activityName}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      {(isGeneralForm || isGeneralCategoryForm) && (
        <GeneralWithCategoriesForm
          isGeneralCategoryForm={isGeneralCategoryForm}
          generalServices={generalServices}
        />
      )}
      {isElectricityForm && (
        <ElectricityForm generalServices={generalServices} />
      )}
      {isWaterForm && <WaterForm generalServices={generalServices} />}
      {isNewCraft && <NewCraftForm craftsData={craftsData} />}
      {isClearanceCertificate && (
        <ClearanceCertificateForm
          clearanceCertificateData={clearanceCertificateData}
        />
      )}

      <Box
        sx={{
          background: "#fff",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
          width: isMobile ? "unset" : 300,
        }}
      >
        <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
          المرفقات
        </Typography>
      </Box>

      {isAttachmentsLoading ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Grid
          container
          sx={{ backgroundColor: "#fff", mt: 2, p: 2, width: "100%" }}
          spacing={isMobile ? 3 : 0}
        >
          <Grid item md={3} sm={4} xs={8}>
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              نوع المرفق
            </Typography>
          </Grid>
          <Grid item md={9} xs={4}>
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              استعراض
            </Typography>
          </Grid>
          {attachments?.map((t, index) => {
            const hasAttachment = t?.fileName && t.path;
            return (
              <React.Fragment key={t.attachmentId || index}>
                <Grid item md={3} xs={8}>
                  <Typography>{`${t.attachmentTypeName}${
                    t?.isMandatory ? " *" : ""
                  }`}</Typography>
                </Grid>
                <Grid item md={9} xs={4}>
                  <IconButton
                    color={hasAttachment ? "primary" : "default"}
                    disabled={!hasAttachment}
                    onClick={() => showDialog(t)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      )}

      {open && (
        <PreviewAttachmentDialog
          open={open}
          handleClose={hideDialog}
          attachmentDetails={attachmentDetails}
        />
      )}
    </Stack>
  );
};

export default OrderDetailsForm;
