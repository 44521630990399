import { GlobalStyles as MuiGlobalStyles } from '@mui/system';

const GlobalStyles = () => (
    <MuiGlobalStyles
        styles={`
      @font-face {
        font-family: 'Dubai';
        font-style: normal;
        font-weight: 400;
        src: url(/fonts/DubaiW23-Regular.ttf) format('truetype');
      }
      @font-face {
        font-family: 'Dubai';
        font-style: normal;
        font-weight: 300;
        src: url(/fonts/DubaiW23-Light.ttf) format('truetype');
      }
      @font-face {
        font-family: 'Dubai';
        font-style: normal;
        font-weight: 500;
        src: url(/fonts/DubaiW23-Medium.ttf) format('truetype');
      }
      @font-face {
        font-family: 'Dubai';
        font-style: normal;
        font-weight: 700;
        src: url(/fonts/DubaiW23-Bold.ttf) format('truetype');
      }

      body {
        font-family: 'Dubai', Arial, sans-serif;
      }
    `}
    />
);

export default GlobalStyles;
