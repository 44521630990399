import {
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Box,
  Link,
  Switch,
  FormControlLabel,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import React, { useContext, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { UserDetailsContext } from "../../Context/userDetailsContext";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { LoadingButton } from "@mui/lab";

const LoginForm = () => {
  const { setUserDetails, userDetails } = useContext(UserDetailsContext);

  const {
    handleSubmit,
    control,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      idNumber: "", // Ensure default values are provided
      password: "",
      rememberMe: true,
    },
  });

  const nav = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onPasswordClicked = () => {
    setShowPassword((prevState) => !prevState);
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const formData = new URLSearchParams();
      formData.append("username", data.idNumber);
      formData.append("password", data.password);
      formData.append("rememberMe", data.rememberMe);

      const userData = await axios.post("/auth/login", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      });

      setIsLoading(false);
      setUserDetails({ ...userData, isAuthenticated: true });
      nav("/");
    } catch (e) {
      setIsLoading(false);
      setError("password", { message: e.message });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "100%", display: "flex", justifyContent: "center" }}
    >
      <Stack sx={{ p: 3, width: isMobile ? "unset" : "90%", gap: 2 }}>
        <Typography
          sx={{ fontWeight: "bold", alignSelf: "center" }}
          color="primary.main"
          variant="h5"
        >
          دخول المستخدم
        </Typography>
        <Stack>
          <Typography sx={{ fontWeight: "bold" }}>رقم الهوية</Typography>
          <Controller
            name="idNumber"
            control={control}
            rules={{
              required: "يجب ادخال رقم الهوية",
              minLength: { value: 9, message: "رقم الهوية مكون من 9 ارقام" },
              maxLength: { value: 9, message: "رقم الهوية مكون من 9 ارقام" },
              pattern: {
                value: /^[0-9]{9}$/,
                message: "رقم الهوية مكون من 9 ارقام",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="standard"
                inputProps={{
                  style: {
                    textAlign: "center",
                    height: 37,
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0,
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  placeholder: "ادخل رقم الهوية",
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 48,
                          height: 48,
                          backgroundColor: "#EAF0F7",
                          borderBottomRightRadius: 10,
                          borderTopRightRadius: 10,
                        }}
                      >
                        <PersonIcon color="primary" />
                      </Box>
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: "start",
                  },
                }}
                error={!!errors.idNumber}
                helperText={errors.idNumber ? errors.idNumber.message : ""}
              />
            )}
          />
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: "bold" }}>كلمة المرور</Typography>
          <Controller
            name="password"
            control={control}
            rules={{
              required: "يجب ادخال كلمة المرور",
              minLength: {
                value: 3,
                message: "كلمة المرور يجب ان تكون اكثر من 3 خانات",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                type={showPassword ? "text" : "password"}
                variant="standard"
                inputProps={{
                  style: {
                    textAlign: "center",
                    height: 37,
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0,
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  placeholder: "ادخل كلمة المرور",
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box
                        sx={{
                          backgroundColor: "#EAF0F7",
                          p: 0.5,
                          borderBottomRightRadius: 10,
                          borderTopRightRadius: 10,
                        }}
                      >
                        <IconButton onClick={onPasswordClicked}>
                          {showPassword ? (
                            <VisibilityOffIcon color="primary" />
                          ) : (
                            <VisibilityIcon color="primary" />
                          )}
                        </IconButton>
                      </Box>
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: "start",
                  },
                }}
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ""}
              />
            )}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link href="/forget-password">نسيت كلمة المرور</Link>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>تذكرني</Typography>
            <Controller
              name="rememberMe"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      onChange={field.onChange}
                      checked={field.value}
                    />
                  }
                />
              )}
            />
          </Box>
        </Stack>
        <LoadingButton
          type="submit"
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          sx={{ width: "80%", alignSelf: "center" }}
          variant="contained"
        >
          <Typography>تسجيل الدخول</Typography>
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default LoginForm;
