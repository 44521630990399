import {
  Box,
  Typography,
  Grid,
  Stack,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { passwordValidationPattern } from "../../constants/index";

const ChangePassword = () => {
  const [isLoading, setIsloading] = useState(false);
  const [showRecentPassword, setShowRecentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirmation, setShowNewPAsswordConfirmation] =
    useState(false);

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    trigger,
    watch,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const watchedValues = watch();

  const onSubmit = async (data) => {
    try {
      setIsloading(true);

      const response = await axios.put("user/password", {
        oldPassword: data.recentPassword,
        newPassword: data.newPassword,
      });
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
    }
  };

  const onShowRecentPassword = () => {
    setShowRecentPassword((prev) => !prev);
  };
  const onShowNewPAssword = () => {
    setShowNewPassword((prev) => !prev);
  };
  const onShowNewPasswordConfirmation = () => {
    setShowNewPAsswordConfirmation((prev) => !prev);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Stack gap={2} sx={{"& .MuiInput-root":{backgroundColor: "inherit"}}}>
          <Grid sx={{ width: "100%" }} spacing={2} container>
            <Grid item md={5} xs={12}>
              <Typography
                sx={{ fontWeight: "bold", marginBottom: 1 }}
                color={"primary"}
              >
                ادخل كلمة المرور الحالية
              </Typography>
              <Controller
                name="recentPassword"
                control={control}
                rules={{
                  required: "يجب ادخال كلمة المرور الحالية",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type={showRecentPassword ? "text" : "password"}
                    variant="standard"
                    inputProps={{
                      style: {
                        textAlign: "center",
                        height: 37,
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                      placeholder: "ادخل كلمة المرور",
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              backgroundColor: "#EAF0F7",
                              p: 0.5,
                              borderBottomRightRadius: 10,
                              borderTopRightRadius: 10,
                            }}
                          >
                            <IconButton onClick={onShowRecentPassword}>
                              {showRecentPassword ? (
                                <VisibilityOffIcon color="primary" />
                              ) : (
                                <VisibilityIcon color="primary" />
                              )}
                            </IconButton>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    FormHelperTextProps={{
                      sx: {
                        textAlign: "start",
                      },
                    }}
                    error={!!errors.recentPassword}
                    helperText={
                      errors.recentPassword ? errors.recentPassword.message : ""
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid sx={{ width: "100%" }} spacing={2} container>
            <Grid item md={5} xs={12}>
              <Typography
                sx={{ fontWeight: "bold", marginBottom: 1 }}
                color={"primary"}
              >
                ادخل كلمة المرور الجديدة
              </Typography>
              <Controller
                name="newPassword"
                control={control}
                rules={{
                  required: "يجب ادخال كلمة المرور",

                  pattern: {
                    value: passwordValidationPattern,
                    message:
                      "كلمة المرور يجب ان تحتوي على الاقل على 8 خانات, حروف كبيرة و صغيرة وأرقام",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type={showNewPassword ? "text" : "password"}
                    variant="standard"
                    fullWidth
                    inputProps={{
                      style: {
                        textAlign: "center",
                        height: 37,
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                      placeholder: "ادخل كلمة المرور",
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              backgroundColor: "#EAF0F7",
                              p: 0.5,
                              borderBottomRightRadius: 10,
                              borderTopRightRadius: 10,
                            }}
                          >
                            <IconButton onClick={onShowNewPAssword}>
                              {showNewPassword ? (
                                <VisibilityOffIcon color="primary" />
                              ) : (
                                <VisibilityIcon color="primary" />
                              )}
                            </IconButton>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    FormHelperTextProps={{
                      sx: {
                        textAlign: "start",
                      },
                    }}
                    error={!!errors.newPassword}
                    helperText={
                      errors.newPassword ? errors.newPassword.message : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <Typography
                sx={{ fontWeight: "bold", marginBottom: 1 }}
                color={"primary"}
              >
                تاكيد كلمة المرور
              </Typography>
              <Controller
                name="newPasswordConfirmation"
                control={control}
                rules={{
                  required: "يجب ادخال تأكيد كلمة المرور",
                  validate: (value) => {
                    return value === watchedValues.newPassword
                      ? true
                      : "كلمة المرور غير مطابقة";
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type={showNewPasswordConfirmation ? "text" : "password"}
                    variant="standard"
                    fullWidth
                    inputProps={{
                      style: {
                        textAlign: "center",
                        height: 37,
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                      placeholder: "ادخل كلمة المرور",
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              backgroundColor: "#EAF0F7",
                              p: 0.5,
                              borderBottomRightRadius: 10,
                              borderTopRightRadius: 10,
                            }}
                          >
                            <IconButton onClick={onShowNewPasswordConfirmation}>
                              {showNewPasswordConfirmation ? (
                                <VisibilityOffIcon color="primary" />
                              ) : (
                                <VisibilityIcon color="primary" />
                              )}
                            </IconButton>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    FormHelperTextProps={{
                      sx: {
                        textAlign: "start",
                      },
                    }}
                    error={!!errors.newPasswordConfirmation}
                    helperText={
                      errors.newPasswordConfirmation
                        ? errors.newPasswordConfirmation.message
                        : ""
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 5,
            gap: 2,
            justifyContent: "end",
          }}
        >
          <LoadingButton
            type="submit"
            sx={{ width: "20%", alignSelf: "center" }}
            variant="contained"
            loading={isLoading}
          >
            <Typography>حفظ</Typography>
          </LoadingButton>
        </Box>
      </form>
    </>
  );
};

export default ChangePassword;
