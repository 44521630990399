import React from "react";
import { Box, Typography, Grid, TextField, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import { isMobile } from "react-device-detect";

const ElectricityComponent = ({
  propertyUsage,
  currentTypes,
  control,
  errors,
}) => {
  return (
    <>
      <Box
        sx={{
          background: "#fff",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
          width: isMobile ? "unset" : 300,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          استخدام العقار
        </Typography>
      </Box>
      <Grid
        sx={{ background: "#fff", p: 2, mt: 1, width: "100%" }}
        spacing={4}
        container
      >
        <Grid item xs={12} md={4}>
          <Typography sx={{ fontWeight: "bold" }}>استخدام العقار</Typography>

          <Controller
            name="propertyUsage"
            control={control}
            defaultValue=""
            rules={{
              required: "هذا الحقل مطلوب",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                select
                fullWidth
                variant="outlined"
                InputProps={{
                  placeholder: "",
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: "start",
                  },
                }}
                error={!!errors.propertyUsage}
                helperText={
                  errors.propertyUsage ? errors.propertyUsage.message : ""
                }
              >
                {propertyUsage.map((option) => (
                  <MenuItem key={option.name} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography sx={{ fontWeight: "bold" }}>وصف العقار</Typography>
          <Controller
            name="propertyDescription"
            control={control}
            rules={{
              required: "هذا الحقل مطلوب",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                multiline
                variant="outlined"
                InputProps={{
                  placeholder: "",
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: "start",
                  },
                }}
                error={!!errors.propertyDescription}
                helperText={
                  errors.propertyDescription
                    ? errors.propertyDescription.message
                    : ""
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography sx={{ fontWeight: "bold" }}>
            نوع التيار الكهربائي
          </Typography>

          <Controller
            name="currentType"
            control={control}
            defaultValue="" // Ensure default value is set
            rules={{
              required: "هذا الحقل مطلوب",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                select
                fullWidth
                variant="outlined"
                InputProps={{
                  placeholder: "",
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: "start",
                  },
                }}
                error={!!errors.currentType}
                helperText={
                  errors.currentType ? errors.currentType.message : ""
                }
              >
                {currentTypes.map((option) => (
                  <MenuItem key={option.name} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ElectricityComponent;
