import Box from "@mui/material/Box";
import { Grid, Typography, Stack, Divider } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";

const ElectricityForm = ({ generalServices }) => {
  return (
    <>
      <Box
        sx={{
          background: "#fff",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
          width: isMobile ? "unset" : 300,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          استخدام العقار
        </Typography>
      </Box>
      <Grid
        sx={{ backgroundColor: "#fff", mt: 2, p: 2, width: "100%" }}
        spacing={3}
        container
      >
        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>
              {" "}
              استخدام العقار :
            </Typography>
            <Typography>{generalServices?.usePropertyType?.name}</Typography>
          </Stack>
        </Grid>

        <Grid item md={9} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}> وصف العقار :</Typography>
            <Typography>{generalServices.propertyDescription} </Typography>
          </Stack>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>
              نوع التيار الكهربائي :
            </Typography>
            <Typography>
              {generalServices?.electricityVoltageType?.name}{" "}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default ElectricityForm;
