import React, { useEffect, useState } from "react";
import MyOrdersContent from "../Components/MyOrders/index";
import axios from "axios";

const MyOrders = () => {
  const [appicationsData, setApplicationsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getOrdersData = async () => {
      try {
        const data = await axios.get("/application");
        setIsLoading(false);
        setApplicationsData(data);
      } catch (e) {
        setIsLoading(false);
      }
    };
    getOrdersData();
  }, []);

  return (
    <MyOrdersContent appicationsData={appicationsData} isLoading={isLoading} />
  );
};

export default MyOrders;
