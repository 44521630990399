import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { getApplicationsCount } from "../../utils/applicationsTypesCounter";
import { isMobile } from 'react-device-detect';

const data = [
  {
    title: "طلب جديد",
    color: "white",
    code: 1,
  },
  {
    title: "طلب قيد التنفيذ",
    color: "#FFE18C",
    code: 2,
  },
  {
    title: "طلب معلق",
    color: "#65E0FF",
    code: 3,
  },
  {
    title: "طلب مكتمل",
    color: "#72EF9B",
    code: 4,
  },
  {
    title: "طلب مرفوض",
    color: "#FF3A3A",
    code: 5,
  },
  {
    title: "ملغى",
    color: "#D1D1D1",
    code: 6,
  },
  {
    title: "مجموع الطلبات",
    color: "#FFFDEC",
    code: 7,  // Added a unique code for the last item
  },
];

const DataBoard = ({ appicationsData }) => {
  return (
    <Box>
      <Box sx={{ marginBottom: 3, fontWeight: "bold" }}>
        <Typography variant="h7">ملخص طلب الخدمة</Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap", justifyContent: isMobile ? "center" : "" }}>
        {data.map((item) => {
          return (
            <Paper
              key={item.code} 
              elevation={2}
              square={false}
              sx={{
                height: 150,
                width: 150,
                backgroundColor: item.color,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                {getApplicationsCount(appicationsData, item.code)}
              </Typography>
              <Typography variant="h8">{item.title}</Typography>
            </Paper>
          );
        })}
      </Box>
    </Box>
  );
};

export default DataBoard;
