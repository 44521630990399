import {useEffect, useState} from "react";
import axios from "axios";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    CircularProgress, Grid,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ServiceOrderCard from "../Components/ServiceOrderCard";

const ServicesGuide = () => {
    const [services, setServices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        axios.get("/MunicipalityService/MunicipalityServicesGuide").then(res => {
            setIsLoading(false)
            setServices(res);
        }).catch(e => {
            setIsLoading(false)
        });
    }, []);
    if (isLoading) {
        return (
            <Box sx={{mt: 15, textAlign: "center"}}>
                <CircularProgress size={80}/>
            </Box>
        )
    }

    return (
        <Paper sx={{p: 3, background: "#F9FAFC"}} elevation={5}>
            <Stack gap={3}>
                {
                    services.map(t => {
                        return (
                            <Accordion key={t.name}>
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownIcon color="primary"/>}
                                >
                                    <Typography sx={{fontWeight: "bold"}} variant="h6"
                                                color="primary.main">{t.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid spacing={3} container>

                                        {t.publicServices.map(f => (
                                            <Grid item lg={4}  md={6} xs={12} key={f.name}>
                                                <ServiceOrderCard pageName="/service-guide-details" { ...f} groupName={t.name} name={f.name}/>
                                            </Grid>
                                        ))
                                        }
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })

                }
            </Stack>
        </Paper>

    )
}
export default ServicesGuide;