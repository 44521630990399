import React, { useEffect, useContext, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Paper, Stack } from "@mui/material";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import config from "../../config";

const MobileMyServices = ({ services, isLoading }) => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogData, setDialogData] = useState({
    serviceType: "",
    location: "",
    subscriberCode: "",
    beneficiaryType: "",
    address: "",
  });
  const [accruals, setAccruals] = useState([]);
  const [accrualsLoading, setAccrualsLoading] = useState(false);
  const showAccruals = config.showAccrualData;

  useEffect(() => {
    const getAccrualsData = async () => {
      try {
        if (showAccruals) {
          setAccrualsLoading(true);
          const data = await axios.get("/User/ServicesAccruals");
          setAccruals(data);
          setAccrualsLoading(false);
        }
      } catch (e) {
        setAccrualsLoading(false);
        console.log("e", e);
      }
    };
    getAccrualsData();
  }, []);

  const handleCloseDialog = () => {
    setDialogData({});
    setOpenDialog(false);
  };

  const handleViewMessageClick = ({
    serviceType,
    location,
    subscriberCode,
    beneficiaryType,
    address,
    accruals,
  }) => {
    setDialogData({
      serviceType,
      location,
      subscriberCode,
      beneficiaryType,
      address,
      accruals,
    });
    setOpenDialog(true);
  };

  const getAccruals = (subscriberId) => {
    const accrualsData = accruals.find(
      (item) => item?.subscriberId === subscriberId
    );
    return accrualsData?.subscriberId
      ? `${accrualsData?.balance} ${accrualsData?.currency}`
      : "";
  };

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        sx={{
          "& .MuiDialog-paper": {
            border: "2px solid #BF3C4E", // Set the border color here
            padding: "20px",
            borderRadius: "10px",
          },
        }}
      >
        <Stack gap={3} sx={{ marginBottom: 2, padding: { xs: 3, md: 4 } }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                رمز الاشتراك :
              </Typography>
              <Typography>{dialogData.subscriberCode}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>نوع الخدمة :</Typography>
              <Typography>{dialogData.serviceType}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>الموقع :</Typography>
              <Typography>{dialogData.location}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                نوع المستفيد :
              </Typography>
              <Typography>{dialogData?.beneficiaryType}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>العنوان :</Typography>
            <Typography>{dialogData.address}</Typography>
          </Box>
          {showAccruals ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>المستحقات :</Typography>
              <Typography>{dialogData?.accruals}</Typography>
            </Box>
          ) : (
            <></>
          )}
        </Stack>
        <Button
          type="sumbit"
          sx={{ width: "30%", alignSelf: "center" }}
          variant="contained"
          onClick={handleCloseDialog}
        >
          <Typography>اغلاق</Typography>
        </Button>
      </Dialog>
      {
        <Stack gap={2}>
          {services?.map((t) => {
            return (
              <Paper sx={{ p: 2 }} elevation={5}>
                <Stack gap={2}>
                  <Box display="flex" gap={2}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      رمز الاشتراك:
                    </Typography>
                    <Typography>{t.subscriberCode}</Typography>
                  </Box>
                  <Box display="flex" gap={2}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      نوع الخدمة:
                    </Typography>
                    <Typography>{t.serviceType.name}</Typography>
                  </Box>
                  <Box display="flex" gap={2}>
                    <Typography sx={{ fontWeight: "bold" }}>الموقع:</Typography>
                    <Typography>{t.location}</Typography>
                  </Box>
                  <Box display="flex" gap={2}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      نوع المستفيد:
                    </Typography>
                    <Typography>{t?.beneficiaryType?.name}</Typography>
                  </Box>

                  {showAccruals ? (
                    <Box display="flex" gap={2}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        المستحقات :
                      </Typography>
                      {accrualsLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <Typography>{getAccruals(t.subscriberId)}</Typography>
                      )}
                    </Box>
                  ) : (
                    <></>
                  )}

                  <Box display="flex" alignItems="center" gap={2}>
                    <Typography sx={{ fontWeight: "bold" }}>عرض:</Typography>
                    <IconButton
                      onClick={() =>
                        handleViewMessageClick({
                          serviceType: t.serviceType.name,
                          location: t.location,
                          subscriberCode: t.subscriberCode,
                          beneficiaryType: t.beneficiaryType?.name,
                          address: t.addressAr,
                          accruals: getAccruals(t.subscriberId),
                        })
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Box>
                </Stack>
              </Paper>
            );
          })}
        </Stack>
      }
    </Box>
  );
};

export default MobileMyServices;
