import React, { useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  useGridApiRef,
} from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation, useNavigate } from "react-router-dom";
import { Paper } from "@mui/material";
import { getApplicationsCount } from "../../utils/applicationsTypesCounter";
import { convertTimeFormat } from "../../utils/timeFormatter";
import CustomPager from "../CustomPager";
import { CircularProgress } from "@mui/material";

const columnHeader = (params) => {
  return (
    <Box>
      <Typography variant="h7"> {params.colDef.headerName}</Typography>
    </Box>
  );
};

const statusColorMapper = (status) => {
  switch (status) {
    case 1:
      return "#fff";
    case 2:
      return "#FFE18C";
    case 3:
      return "#65E0FF";
    case 4:
      return "#72EF9B";
    case 5:
      return "#FF3A3A";
    case 6:
      return "#D1D1D1";
  }
};

const DesktopMyOrders = ({ appicationsData, isLoading }) => {
  const location = useLocation();
  const applicationsTypes = [
    {
      title: "طلب جديد",
      color: "white",
      id: 1,
    },
    {
      title: "طلب قيد التنفيذ",
      color: "#FFE18C",
      id: 2,
    },
    {
      title: "طلب معلق",
      color: "#65E0FF",
      id: 3,
    },
    {
      title: "طلب مكتمل",
      color: "#72EF9B",
      id: 4,
    },
    {
      title: "طلب مرفوض",
      color: "#FF3A3A",
      id: 5,
    },
    {
      title: "ملغى",
      color: "#D1D1D1",
      id: 6,
    },
  ];

  const navigate = useNavigate();

  const columns = [
    {
      field: "subscriberServiceFullNumber",
      headerName: "رقم الطلب",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      headerClassName: "table-header",
      align: "center",
    },
    {
      field: "serviceName",
      headerName: "نوع الطلب",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      headerClassName: "table-header",
      align: "center",
    },
    {
      field: "applicationFee",
      headerName: "رسوم الطلب",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      headerClassName: "table-header",
      align: "center",
      valueGetter: (obj) => {
        return `${obj.value} ${obj.row.currencyChar3Code} `;
      },
    },
    {
      field: "insertDate",
      headerName: "تاريخ الطلب",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      headerClassName: "table-header",
      align: "center",
      valueGetter: (obj) => {
        return convertTimeFormat(obj.value);
      },
    },
    {
      field: "activityName",
      headerName: "النشاط",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      headerClassName: "table-header",
      align: "center",
    },
    {
      field: "actions",
      headerName: "مراجعة",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      align: "center",
      headerClassName: "table-header",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <GridActionsCellItem
          icon={<VisibilityIcon />}
          label="View"
          onClick={() => {
            handleViewClick(params);
          }}
        />
      ),
    },
  ];

  const handleViewClick = (item) => {
    navigate(`/order-details/${item.row.subscriberServiceId}`, {
      state: { ...location?.state, groupName: item.row.serviceName },
    });
  };
  const apiRef = useGridApiRef();

  if (isLoading) {
    return (
      <Box sx={{ mt: 5, textAlign: "center" }}>
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: 3,
        width: "100%",
        "& .table-header": {
          backgroundColor: "#DFE7F2",
          color: "#BF3C4E",
          border: "1px solid white !important",
        },
        "& .MuiDataGrid-columnHeaders": {
          overflowX: { xs: "scroll", md: "hidden" },
        },
        "& .MuiDataGrid-footerContainer": {
          direction: "ltr",
        },
        "& .MuiDataGrid-row:hover": {
          backgroundColor: "#d9e0eb",
        },
        "& .New": {
          backgroundColor: "white",
        },
        "& .InProgress": {
          backgroundColor: "#FFE18C",
        },
        "& .Pending": {
          backgroundColor: "#65E0FF",
        },
        "& .Complete": {
          backgroundColor: "#72EF9B",
        },
        "& .Rejected": {
          backgroundColor: "#FF3A3A",
        },
        "& .Canceled": {
          backgroundColor: "#D1D1D1",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 7,
          marginBottom: 3,
          justifyContent: "center",
        }}
      >
        {applicationsTypes.map((application) => {
          const applicationCount = getApplicationsCount(
            appicationsData,
            application.id
          );
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                flexDirection: "column",
              }}
              key={application.id}
            >
              <Paper
                elevation={2}
                sx={{
                  height: 40,
                  width: 40,
                  borderRadius: "50%",
                  backgroundColor: application.color,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant={"caption"}>{applicationCount}</Typography>
              </Paper>
              <Typography variant={"caption"}>{application.title}</Typography>
            </Box>
          );
        })}
      </Box>
      <DataGrid
        rows={appicationsData.map((item, index) => ({ ...item, id: index }))}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5]}
        apiRef={apiRef}
        slots={{
          pagination: CustomPager,
        }}
        disableRowSelectionOnClick
        getRowClassName={(params) => {
          return params.row.status.name;
        }}
      />
    </Box>
  );
};

export default DesktopMyOrders;
