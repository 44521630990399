import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./Theme";
import "./App.css";
import { UserDetailsProvider } from "./Context/userDetailsContext";
import MainApp from "./pages/MainApp";
import GlobalStyles from "./GlobalStyles";
import { ConfigLinksDetailsProvider } from "./Context/cofingLinksDetailsContext";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./appInsights";

function App() {
  return (
    <div className="App">
      <ConfigLinksDetailsProvider>
        <UserDetailsProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles />

            <AppInsightsContext.Provider value={reactPlugin}>
              <MainApp />
            </AppInsightsContext.Provider>
          </ThemeProvider>
        </UserDetailsProvider>
      </ConfigLinksDetailsProvider>
    </div>
  );
}

export default App;
