import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Dialog,
  IconButton,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { isMobile } from "react-device-detect";
import axios from "axios";
import {
  passwordValidationPattern,
  validFileTypes,
} from "../../constants/index";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { debounce } from "lodash";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PreviewAttachmentDialog from "../PreviewAttachmentDialog";
import { fileToBase64 } from "../../utils/fileToBase64";
import MessageModal from "../MessageModal";
import CancelIcon from "@mui/icons-material/Cancel";
import { ConfigLinksDetailsContext } from "../../Context/cofingLinksDetailsContext";

const validationFields = {
  0: [
    "documentType",
    "documentNumber",
    "applicationLocation",
    "birthDate",
    "gender",
    "firstNameAr",
    "fatherNameAr",
    "grandFatherNameAr",
    "familyNameAr",
    "mainMobileNumber",
    "subMobileNumber",
    "user_email",
    "preferredContact",
    "placeOfResidence",
    "city",
    "address",
    "notes",
    "idPicture",
  ],
  1: ["userPassword", "passwordConfirmation"],
  2: [],
};

const SignupForm = ({ activeStep, setActiveStep, stepsLength }) => {
  const [documentTypes, setDocumentTypes] = useState([]);
  const [genders, setGenders] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openFailiurModal, setOpenFailiurModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileToPreviewData, setFileToPreviewData] = useState();
  const [showFile, setShowFile] = useState(false);
  const { configLinks } = useContext(ConfigLinksDetailsContext);
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    trigger,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const navigate = useNavigate();
  const { userPassword, idPicture } = watch();
  const onLastFormPage = activeStep === 2;
  useEffect(() => {
    setIsLoading(true);
    const getDropDownData = async () => {
      try {
        const data = await Promise.all([
          axios.get(`/lookup/DocumentsTypes?id=1`),
          axios.get(`/lookup/genders`),
          axios.get(`/lookup/notificationTypes`),
          axios.get(`/lookup/countries`),
        ]);
        setIsLoading(false);
        setDocumentTypes(data[0]);
        setGenders(data[1]);
        setNotificationTypes(data[2]);
        setCountries(data[3]);
      } catch (e) {
        console.log("e", e);
      }
    };
    getDropDownData();
  }, []);

  const validateUsernameAPI = async (username, documentType) => {
    try {
      const response = await axios.post("/validate/documentNo", {
        documentNumber: username,
        documentType: documentType,
      });

      return { isValid: true };
    } catch (error) {
      return {
        isValid: false,
        errorMessage: error?.errors?.DocumentNumber?.[0],
      };
    }
  };

  const debouncedValidateUsername = useCallback(
    debounce(async (value, documentType, setError) => {
      const responseObject = await validateUsernameAPI(value, documentType);
      if (!responseObject.isValid) {
        setError("documentNumber", {
          type: "validate",
          message: responseObject.errorMessage,
        });
      }
    }, 500),
    []
  );

  const handleNext = async () => {
    if (activeStep === 2) {
      handleSubmit(onSubmit)();
      return;
    }
    const fieldsToValidate = validationFields[activeStep];
    const result = await trigger(fieldsToValidate);
    if (result) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmit = async (data) => {
    try {
      const idPictureFormData = new FormData();
      if (data.idPicture?.fileObject?.name) {
        idPictureFormData.append(
          `attachments[0].File`,
          data.idPicture?.fileObject
        );
        idPictureFormData.append(`attachments[0].AttachmentType`, 1);
      }

      const userData = {
        citizenInfoDto: {
          documentNumber: data.documentNumber,
          documentType: data.documentType,
          issuePlace: data.applicationLocation,
          birthdate: data.birthDate.toISOString(),
          gender: data.gender,
          firstNameAr: data.firstNameAr,
          secondNameAr: data.fatherNameAr,
          thirdNameAr: data.grandFatherNameAr,
          familyNameAr: data.familyNameAr,
          phone1: data.mainMobileNumber,
          phone2: data.subMobileNumber,
          email: data.user_email,
          preferredChannel: +data.preferredContact,
          country: data.placeOfResidence,
          city: data.city,
          address: data.address,
          note: data.notes,
        },
        userSecurityInfo: {
          password: data.userPassword,
          userName: data.documentNumber,
        },
        attachments: idPictureFormData,
      };

      const response = await axios.post("user", userData);
      setOpenSuccessModal(true);
    } catch (e) {
      setOpenFailiurModal(true);
      console.log("e", e);
    }
  };

  const onCloseModal = () => {
    navigate("/login");
  };

  const onViewDocumentClick = (file) => {
    setFileToPreviewData(file);
    setShowFile(true);
  };

  const handleFileUploadClick = () => {
    document.getElementById(`idPicture`).click();
  };

  const onImageUpload = async (field, file) => {
    const maxSizeInMB = configLinks.fileSizeLimitMb;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Convert MB to bytes
    try {
      if (file && validFileTypes.includes(file.type)) {
        if (file.size <= maxSizeInBytes) {
          field.onChange({ fileObject: file });
          // Clear any previous error message
          setError("idPicture", null);
        } else {
          // Set an error message if the file size exceeds 2 MB
          setError(`idPicture`, {
            type: "validate",
            message: `حجم الملف لا يمكن ان يتجاوز ${configLinks.fileSizeLimitMb} MB`,
          });
        }
      } else {
        // Set an error message if the file type is invalid
        setError("idPicture", {
          type: "validate",
          message: "الرجاء تحميل ملف صالح (JPEG, JPG, PNG, PDF)",
        });
      }
    } catch (e) {
      console.log("error from input", e);
    }
  };
  const handleCloseFilePreview = () => {
    setShowFile(false);
    setFileToPreviewData();
  };

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Stack
            sx={{
              display: activeStep === 0 || onLastFormPage ? "block" : "none",
            }}
          >
            <Box
              sx={{
                fontWeight: "bold",
                py: 1,
                width: isMobile ? "unset" : 300,
                marginBottom: 1,
              }}
            >
              <Typography
                variant="h6"
                color={"primary"}
                sx={{ fontWeight: "bold" }}
              >
                معلومات الوثيقة
              </Typography>
            </Box>
            <Grid sx={{ width: "100%" }} spacing={2} container>
              <Grid item md={3} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  نوع الوثيقة *
                </Typography>
                <Controller
                  name="documentType"
                  control={control}
                  rules={{
                    required: "هذا الحقل مطلوب",
                  }}
                  defaultValue={1}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={onLastFormPage}
                      select
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        placeholder: "اختر",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.documentType}
                      helperText={
                        errors.documentType ? errors.documentType.message : ""
                      }
                    >
                      {documentTypes.map((option) => (
                        <MenuItem
                          key={option.name}
                          value={option.id}
                          sx={{ direction: "rtl" }}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  رقم الوثيقة *
                </Typography>
                <Controller
                  name="documentNumber"
                  control={control}
                  rules={{
                    required: "هذا الحقل مطلوب",
                    validate: (value) => {
                      const documentType = getValues("documentType");
                      debouncedValidateUsername(value, documentType, setError);
                      return true;
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={onLastFormPage}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        style: {},
                      }}
                      InputProps={{
                        placeholder: "",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.documentNumber}
                      helperText={
                        errors.documentNumber
                          ? errors.documentNumber.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  مكان الاصدار *
                </Typography>
                <Controller
                  name="applicationLocation"
                  control={control}
                  rules={{
                    required: "هذا الحقل مطلوب",
                  }}
                  defaultValue={1}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={onLastFormPage}
                      select
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        placeholder: "اختر",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.applicationLocation}
                      helperText={
                        errors.applicationLocation
                          ? errors.applicationLocation.message
                          : ""
                      }
                    >
                      {countries.map((option) => (
                        <MenuItem
                          key={option.name}
                          value={option.id}
                          sx={{ direction: "rtl" }}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item md={1.5} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  تاريخ الميلاد *
                </Typography>
                <Controller
                  name="birthDate"
                  control={control}
                  rules={{
                    required: "هذا الحقل مطلوب",
                    validate: (value) => {
                      const birthDate = dayjs(value);
                      const today = dayjs();
                      const age = today.diff(birthDate, "year");
                      return age >= 16 || "يجب أن يكون العمر أكبر من 16 عامًا";
                    },
                  }}
                  render={({ field }) => (
                    <Stack>
                      <DatePicker
                        sx={{
                          "& .MuiInputBase-root": {
                            paddingRight: "4px",
                          },
                          "& .MuiInputAdornment-root": {
                            marginLeft: 0,
                          },
                          "& input": {
                            border: "none !important",
                            paddingLeft: 0,
                          },
                          "& button": {
                            padding: 0,
                          },
                          ...(errors.birthDate && {
                            "& fieldset": {
                              borderColor: "#d32f2f",
                            },
                          }),
                        }}
                        disabled={onLastFormPage}
                        {...field}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            error={!!errors.birthDate}
                          />
                        )}
                      />
                      {errors.birthDate && (
                        <Typography variant="caption" color="error">
                          {errors.birthDate.message}
                        </Typography>
                      )}
                    </Stack>
                  )}
                />
              </Grid>
              <Grid item md={1.5} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  الجنس *
                </Typography>
                <Controller
                  name="gender"
                  control={control}
                  defaultValue={genders[0]?.id || ""} // Ensure a default value is provided
                  rules={{
                    required: "هذا الحقل مطلوب",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={onLastFormPage}
                      select
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        placeholder: "اختر",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.gender}
                      helperText={errors.gender ? errors.gender.message : ""}
                    >
                      {genders.map((option) => (
                        <MenuItem
                          key={option.name}
                          value={option.id}
                          sx={{ direction: "rtl" }}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
            {/* /////////////  section 2 ////////////*/}
            <Box
              sx={{
                fontWeight: "bold",
                py: 1,
                width: isMobile ? "unset" : 300,
                marginBottom: 1,
              }}
            >
              <Typography
                variant="h6"
                color={"primary"}
                sx={{ fontWeight: "bold" }}
              >
                الاسم باللغة العربية
              </Typography>
            </Box>
            <Grid sx={{ width: "100%" }} spacing={2} container>
              <Grid item md={3} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  الاسم الاول *
                </Typography>
                <Controller
                  name="firstNameAr"
                  control={control}
                  rules={{
                    required: "هذا الحقل مطلوب",
                    minLength: {
                      value: 2,
                      message: "الرجاء إدخال اسم لا يقل عن حرفين",
                    },
                    pattern: {
                      value:
                        /^[a-zA-Z\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\s]+$/,
                      message: "الرجاء إدخال حروف فقط بدون أرقام",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={onLastFormPage}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        style: {},
                      }}
                      InputProps={{
                        placeholder: "",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.firstNameAr}
                      helperText={
                        errors.firstNameAr ? errors.firstNameAr.message : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  اسم الاب *
                </Typography>
                <Controller
                  name="fatherNameAr"
                  control={control}
                  rules={{
                    required: "هذا الحقل مطلوب",
                    minLength: {
                      value: 2,
                      message: "الرجاء إدخال اسم لا يقل عن حرفين",
                    },
                    pattern: {
                      value:
                        /^[a-zA-Z\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\s]+$/,
                      message: "الرجاء إدخال حروف فقط بدون أرقام",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={onLastFormPage}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        style: {},
                      }}
                      InputProps={{
                        placeholder: "",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.fatherNameAr}
                      helperText={
                        errors.fatherNameAr ? errors.fatherNameAr.message : ""
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  اسم الجد *
                </Typography>
                <Controller
                  name="grandFatherNameAr"
                  control={control}
                  rules={{
                    required: "هذا الحقل مطلوب",
                    minLength: {
                      value: 2,
                      message: "الرجاء إدخال اسم لا يقل عن حرفين",
                    },
                    pattern: {
                      value:
                        /^[a-zA-Z\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\s]+$/,
                      message: "الرجاء إدخال حروف فقط بدون أرقام",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={onLastFormPage}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        placeholder: "",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.grandFatherNameAr}
                      helperText={
                        errors.grandFatherNameAr
                          ? errors.grandFatherNameAr.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  اسم العائلة *
                </Typography>
                <Controller
                  name="familyNameAr"
                  control={control}
                  rules={{
                    required: "هذا الحقل مطلوب",
                    minLength: {
                      value: 2,
                      message: "الرجاء إدخال اسم لا يقل عن حرفين",
                    },
                    pattern: {
                      value:
                        /^[a-zA-Z\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\s]+$/,
                      message: "الرجاء إدخال حروف فقط بدون أرقام",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={onLastFormPage}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        placeholder: "",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.familyNameAr}
                      helperText={
                        errors.familyNameAr ? errors.familyNameAr.message : ""
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* /////////////  section 3 ////////////*/}

            <Box
              sx={{
                fontWeight: "bold",
                py: 1,
                width: isMobile ? "unset" : 300,
                marginBottom: 1,
              }}
            >
              <Typography
                variant="h6"
                color={"primary"}
                sx={{ fontWeight: "bold" }}
              >
                معلومات التواصل
              </Typography>
            </Box>
            <Grid sx={{ width: "100%" }} spacing={2} container>
              <Grid item md={3} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  رقم الموبايل الرئيسي *
                </Typography>
                <Controller
                  name="mainMobileNumber"
                  control={control}
                  rules={{
                    required: "هذا الحقل مطلوب",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "الرجاء إدخال أرقام فقط",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={onLastFormPage}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        style: {},
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.mainMobileNumber}
                      helperText={
                        errors.mainMobileNumber
                          ? errors.mainMobileNumber.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  رقم الموبايل الثانوي
                </Typography>
                <Controller
                  name="subMobileNumber"
                  control={control}
                  rules={{
                    required: false,
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "الرجاء إدخال أرقام فقط",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={onLastFormPage}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        style: {},
                      }}
                      InputProps={{
                        placeholder: "",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.subMobileNumber}
                      helperText={
                        errors.subMobileNumber
                          ? errors.subMobileNumber.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  البريد الاكتروني
                </Typography>
                <Controller
                  name="user_email"
                  control={control}
                  rules={{
                    required: false,
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "الرجاء إدخال بريد إلكتروني صحيح",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="email"
                      disabled={onLastFormPage}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        placeholder: "",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      autoComplete="off"
                      error={!!errors.user_email}
                      helperText={
                        errors.user_email ? errors.user_email.message : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                {onLastFormPage ? (
                  <Stack direction={"row"} alignItems={"center"} mt={6}>
                    <Typography sx={{ fontWeight: "bold", marginLeft: 2 }}>
                      جهة الاتصال المفضلة لدي :
                    </Typography>
                    <Typography>
                      {
                        notificationTypes.find((item) => {
                          const notificationId = getValues("preferredContact");
                          return item.id == notificationId;
                        })?.name
                      }
                    </Typography>
                  </Stack>
                ) : (
                  <>
                    <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                      جهة الاتصال المفضلة لدي
                    </Typography>
                    <Controller
                      disabled={onLastFormPage}
                      name="preferredContact"
                      control={control}
                      defaultValue={"2"}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          {...field}
                          sx={{
                            "& .MuiRadio-root": {
                              // paddingRight: 0
                            },
                          }}
                        >
                          {notificationTypes
                            .sort((a, b) => b.id - a.id)
                            .map((item, index) => (
                              <FormControlLabel
                                key={item.id}
                                sx={{ marginRight: index === 0 ? 0 : 2 }}
                                value={item.id}
                                control={<Radio />}
                                label={item.name}
                              />
                            ))}
                        </RadioGroup>
                      )}
                    />
                  </>
                )}
              </Grid>
            </Grid>
            {/* /////////////  section 4 ////////////*/}
            <Box
              sx={{
                fontWeight: "bold",
                py: 1,
                width: isMobile ? "unset" : 300,
                marginBottom: 1,
              }}
            >
              <Typography
                variant="h6"
                color={"primary"}
                sx={{ fontWeight: "bold" }}
              >
                العنوان
              </Typography>
            </Box>
            <Grid sx={{ width: "100%" }} spacing={2} container>
              <Grid item md={3} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  مكان الاقامة *
                </Typography>
                <Controller
                  name="placeOfResidence"
                  control={control}
                  rules={{
                    required: "هذا الحقل مطلوب",
                  }}
                  defaultValue={1}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={onLastFormPage}
                      select
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        placeholder: "اختر",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.placeOfResidence}
                      helperText={
                        errors.placeOfResidence
                          ? errors.placeOfResidence.message
                          : ""
                      }
                    >
                      {countries.map((option) => (
                        <MenuItem
                          key={option.name}
                          value={option.id}
                          sx={{ direction: "rtl" }}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  المدينة *
                </Typography>
                <Controller
                  name="city"
                  control={control}
                  rules={{
                    required: "هذا الحقل مطلوب",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={onLastFormPage}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        style: {},
                      }}
                      InputProps={{
                        placeholder: "",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.city}
                      helperText={errors.city ? errors.city.message : ""}
                    />
                  )}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  العنوان
                </Typography>
                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={onLastFormPage}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        placeholder: "",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.address}
                      helperText={errors.address ? errors.address.message : ""}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  الملاحظات
                </Typography>
                <Controller
                  name="notes"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={onLastFormPage}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        placeholder: "",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      minRows={1}
                      maxRows={10}
                      multiline
                      error={!!errors.notes}
                      helperText={errors.notes ? errors.notes.message : ""}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* /////////////  section 5 ////////////*/}
            {onLastFormPage && !idPicture ? (
              <></>
            ) : (
              <>
                <Box
                  sx={{
                    fontWeight: "bold",
                    py: 1,
                    width: isMobile ? "unset" : 300,
                    marginBottom: 1,
                  }}
                >
                  <Typography
                    variant="h6"
                    color={"primary"}
                    sx={{ fontWeight: "bold" }}
                  >
                    المرفقات
                  </Typography>
                </Box>
                <Grid sx={{ width: "100%" }} spacing={2} container>
                  <Grid item md={6} xs={12}>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        صورة الهوية
                      </Typography>
                      <Controller
                        name="idPicture"
                        control={control}
                        rules={{
                          required: "هذا الحقل مطلوب",
                        }}
                        render={({ field }) => {
                          return (
                            <>
                              {!onLastFormPage && (
                                <>
                                  <input
                                    type="file"
                                    id={`idPicture`}
                                    style={{ display: "none" }}
                                    onChange={(e) =>
                                      onImageUpload(field, e.target.files[0])
                                    }
                                  />
                                  <IconButton
                                    onClick={() => handleFileUploadClick()}
                                  >
                                    <AttachFileIcon />
                                  </IconButton>
                                </>
                              )}
                              {field.value && (
                                <>
                                  <IconButton
                                    onClick={() =>
                                      onViewDocumentClick(field.value)
                                    }
                                  >
                                    <VisibilityIcon color={"primary"} />
                                  </IconButton>
                                </>
                              )}
                            </>
                          );
                        }}
                      />
                    </Stack>
                    {errors.idPicture && (
                      <Typography color="error">
                        {errors.idPicture.message}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            {fileToPreviewData && (
              <PreviewAttachmentDialog
                open={showFile}
                attachmentDetails={fileToPreviewData}
                handleClose={handleCloseFilePreview}
                isImageUploadOnly={true}
              />
            )}
          </Stack>
          {/* /////////////// 2nd form //////////// */}
          <Stack
            sx={{
              display: activeStep === 1 || onLastFormPage ? "block" : "none",
            }}
          >
            <Box
              sx={{
                fontWeight: "bold",
                py: 1,
                width: isMobile ? "unset" : 300,
                marginBottom: 1,
              }}
            >
              <Typography
                variant="h6"
                color={"primary"}
                sx={{ fontWeight: "bold" }}
              >
                حساب المواطن
              </Typography>
            </Box>
            <Grid sx={{ width: "100%" }} spacing={2} container>
              <Grid item md={3} xs={12}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  اسم المستخدم *
                </Typography>
                <Controller
                  name="documentNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={true}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        style: {},
                      }}
                      InputProps={{
                        placeholder: "",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                md={3}
                xs={12}
                sx={{ display: onLastFormPage ? "none" : "block" }}
              >
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  كلمة المرور *
                </Typography>
                <Controller
                  name="userPassword"
                  control={control}
                  rules={{
                    required: "يجب ادخال كلمة المرور",

                    pattern: {
                      value: passwordValidationPattern,
                      message:
                        "كلمة المرور يجب ان تحتوي على الاقل على 8 خانات, حروف كبيرة و صغيرة وأرقام",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      type={"password"}
                      autoComplete="off"
                      fullWidth
                      InputProps={{
                        placeholder: "",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.userPassword}
                      helperText={
                        errors.userPassword ? errors.userPassword.message : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                sx={{ display: onLastFormPage ? "none" : "block" }}
              >
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  تاكيد كلمة المرور *
                </Typography>
                <Controller
                  name="passwordConfirmation"
                  control={control}
                  rules={{
                    required: "يجب ادخال تأكيد كلمة المرور",
                    validate: (value) =>
                      value === userPassword ? true : "كلمة المرور غير مطابقة",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      type={"password"}
                      autoComplete="off"
                      fullWidth
                      inputProps={{
                        style: {},
                      }}
                      InputProps={{
                        placeholder: "",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.passwordConfirmation}
                      helperText={
                        errors.passwordConfirmation
                          ? errors.passwordConfirmation.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              {onLastFormPage ? <></> : <Grid item md={3} xs={12} />}
            </Grid>
          </Stack>
        </form>
      </LocalizationProvider>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 5,
          gap: 2,
          justifyContent: "end",
        }}
      >
        {activeStep === 0 ? (
          <></>
        ) : (
          <Button
            type={"sumbit"}
            sx={{ width: "20%", alignSelf: "center" }}
            variant="contained"
            onClick={handleBack}
          >
            <Typography>رجوع</Typography>
          </Button>
        )}
        <Button
          type={"sumbit"}
          sx={{ width: "20%", alignSelf: "center" }}
          variant="contained"
          onClick={handleNext}
        >
          <Typography>
            {" "}
            {activeStep === stepsLength - 1 ? "تاكيد التسجيل" : "التالي"}
          </Typography>
        </Button>
      </Box>
      <MessageModal
        buttonText={"اغلاق"}
        interfaceIcon={
          <CheckCircleIcon color={"primary"} sx={{ height: 80, width: 80 }} />
        }
        message={
          "تم تسجيلك بنجاح ، لتفعيل حسابك والاستفادة من الخدمات الالكترونية يرجى التوجه الى البلدية"
        }
        openModal={openSuccessModal}
        onCloseModal={onCloseModal}
      />
      <MessageModal
        buttonText={"اغلاق"}
        interfaceIcon={
          <CancelIcon color={"primary"} sx={{ height: 80, width: 80 }} />
        }
        message={"عذرا, حدث خطأ اثناء التسجيل, يرجى المحاولة فيما بعد"}
        openModal={openFailiurModal}
        onCloseModal={onCloseModal}
      />
    </Box>
  );
};

export default SignupForm;
