import { Menu, MenuItem, Typography } from "@mui/material";
import { useContext } from "react";
import { UserDetailsContext } from "../Context/userDetailsContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const SettingsMenu = ({ open, handleClose, anchorEl }) => {
    const { setUserDetails } = useContext(UserDetailsContext);
    const nav = useNavigate();
    const onLogout = async () => {
        // Make a request to the logout endpoint on your server
        await axios.post('/auth/logout', {}, {
            withCredentials: true
        }).then(() => {
            setUserDetails({});
            // Navigate to the login page
            nav("/login");
            handleClose();
        }).catch((error) => {
            console.log('Logout failed:', error);
        });
    };

    const onMyAccountClick = () => {
        nav("/my-account");
    };
    
    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <MenuItem onClick={onMyAccountClick}><Typography>حسابي</Typography></MenuItem>
            <MenuItem onClick={onLogout}><Typography>تسجيل الخروج</Typography></MenuItem>
            {/* {isMobile && <MenuItem>
                <Typography>English</Typography>
            </MenuItem>} */}

        </Menu>

    )
}
export default SettingsMenu;