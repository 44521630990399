import React from "react";
import { IconButton, Select, MenuItem, Box, Typography } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
} from "@mui/x-data-grid";
import i18n from "i18next";

const CustomPager = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);

  const handlePageChange = (event) => {
    apiRef.current.setPage(event.target.value);
  };

  const handlePageSizeChange = (event) => {
    apiRef.current.setPageSize(event.target.value);
  };
  const start = page * pageSize + 1;
  const end = Math.min((page + 1) * pageSize, rowCount);
  const paginationText = `${start}-${end} of ${rowCount}`;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none !important",
        },
      }}
    >
      {i18n.language === "ar" ? (
        <>
          <Typography>{paginationText}</Typography>

          <IconButton
            onClick={() => apiRef.current.setPage(pageCount - 1)}
            disabled={page >= pageCount - 1}
            sx={{ p: 1 }}
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            onClick={() => apiRef.current.setPage(page + 1)}
            disabled={page >= pageCount - 1}
            sx={{ p: 1 }}
          >
            <NavigateBeforeIcon />
          </IconButton>

          <Select value={page} onChange={handlePageChange} sx={{ mx: 2 }}>
            {Array.from(Array(pageCount).keys()).map((page) => (
              <MenuItem key={page} value={page}>
                {page + 1}
              </MenuItem>
            ))}
          </Select>

          <IconButton
            onClick={() => apiRef.current.setPage(page - 1)}
            disabled={page === 0}
            sx={{ p: 1 }}
          >
            <NavigateNextIcon />
          </IconButton>
          <IconButton
            onClick={() => apiRef.current.setPage(0)}
            disabled={page === 0}
            sx={{ p: 1 }}
          >
            <LastPageIcon />
          </IconButton>

          <Select
            value={pageSize}
            onChange={handlePageSizeChange}
            sx={{ mx: 2 }}
          >
            {[5, 10, 20, 50, 100].map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : (
        <>
          <Select
            value={pageSize}
            onChange={handlePageSizeChange}
            sx={{ mx: 2 }}
          >
            {[5, 10, 20, 50, 100].map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
          <IconButton
            onClick={() => apiRef.current.setPage(0)}
            disabled={page === 0}
            sx={{ p: 1 }}
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            onClick={() => apiRef.current.setPage(page - 1)}
            disabled={page === 0}
            sx={{ p: 1 }}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <Select value={page} onChange={handlePageChange} sx={{ mx: 2 }}>
            {Array.from(Array(pageCount).keys()).map((page) => (
              <MenuItem key={page} value={page}>
                {page + 1}
              </MenuItem>
            ))}
          </Select>
          <IconButton
            onClick={() => apiRef.current.setPage(page + 1)}
            disabled={page >= pageCount - 1}
            sx={{ p: 1 }}
          >
            <NavigateNextIcon />
          </IconButton>
          <IconButton
            onClick={() => apiRef.current.setPage(pageCount - 1)}
            disabled={page >= pageCount - 1}
            sx={{ p: 1 }}
          >
            <LastPageIcon />
          </IconButton>
          <Typography>{paginationText}</Typography>
        </>
      )}
    </Box>
  );
};

export default CustomPager;
