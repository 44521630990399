import React from "react";
import { Box, Typography, Grid, TextField, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import { isMobile } from "react-device-detect";

const CertificateComponent = ({ externalEntities, control, errors }) => {
  return (
    <>
      <Box
        sx={{
          background: "#fff",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
          width: isMobile ? "unset" : 300,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          نموذج التقديم
        </Typography>
      </Box>
      <Grid
        sx={{ background: "#fff", p: 2, mt: 1, width: "100%" }}
        spacing={4}
        container
      >
        <Grid item xs={12} md={4}>
          <Typography sx={{ fontWeight: "bold" }}>جهات خارجية</Typography>

          <Controller
            name="externalEntities"
            control={control}
            defaultValue=""
            rules={{
              required: "هذا الحقل مطلوب",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                select
                fullWidth
                variant="outlined"
                value={field.value || ""}
                FormHelperTextProps={{
                  sx: {
                    textAlign: "start",
                  },
                }}
                error={!!errors.externalEntities}
                helperText={
                  errors.externalEntities ? errors.externalEntities.message : ""
                }
              >
                {externalEntities.map((option) => (
                  <MenuItem key={option.name} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CertificateComponent;
