import Box from "@mui/material/Box";
import { Grid, Typography, Stack, Divider } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";

const NewCraftForm = ({ craftsData }) => {
  return (
    <>
      <Box
        sx={{
          background: "#fff",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
          width: isMobile ? "unset" : 300,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          الحرف و الصناعات
        </Typography>
      </Box>
      <Grid
        sx={{ backgroundColor: "#fff", mt: 2, p: 2, width: "100%" }}
        spacing={3}
        container
      >
        <Grid item md={3} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>
              {" "}
              الحرف و الصناعات :
            </Typography>
            <Typography>{craftsData?.craftName}</Typography>
          </Stack>
        </Grid>

        <Grid item md={3} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>التصنيف :</Typography>
            <Typography>{craftsData?.craftClass} </Typography>
          </Stack>
        </Grid>
        <Grid item md={3} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>الدرجة :</Typography>
            <Typography>{craftsData?.craftGrade} </Typography>
          </Stack>
        </Grid>
        <Grid item md={3} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>الرسوم :</Typography>
            <Typography>
              {`${craftsData?.price} ${craftsData?.currency?.name}`}{" "}
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={3} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>اسم المؤجر :</Typography>
            <Typography>{craftsData?.renterName} </Typography>
          </Stack>
        </Grid>
        <Grid item md={3} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>
              رقم وثيقة المؤجر :
            </Typography>
            <Typography>{craftsData?.renterDocument} </Typography>
          </Stack>
        </Grid>
        <Grid item md={3} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>الاسم التجاري :</Typography>
            <Typography>{craftsData?.tradeName} </Typography>
          </Stack>
        </Grid>
        <Grid item md={3} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>
              رقم الرخصة التجارية :
            </Typography>
            <Typography>{craftsData?.businessLicenseNo} </Typography>
          </Stack>
        </Grid>
        <Grid item md={12} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>الملاحظات :</Typography>
            <Typography>{craftsData?.notes} </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default NewCraftForm;
