import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { Chip, Menu, MenuItem, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useContext } from "react";
import { UserDetailsContext } from "../Context/userDetailsContext";
import PersonIcon from "@mui/icons-material/Person";
import SettingsMenu from "./SettingsMenu";

const getPages = (isAuthenticated) => {
  return [
    {
      title: "الصفحة الرئيسية",
      path: "/",
    },
    // {
    //     title: "عن البلدية",
    //     path: "/test",
    // },
    isAuthenticated && {
      title: "تقديم طلب",
      path: "/services",
    },
    isAuthenticated && {
      title: "طلباتي",
      path: "/my-orders",
    },
    isAuthenticated && {
      title: "اشعاراتي",
      path: "/my-notifications",
    },
    isAuthenticated && {
      title: "خدماتي",
      path: "/my-services",
    },
    {
      title: "دليل الخدمات",
      path: "/services-guide",
    },
    // {
    //     title: "اتصل بنا",
    //     path: "/#",
    // },
  ].filter(Boolean);
};

function ResponsiveNavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const { userDetails, setUserDetails } = useContext(UserDetailsContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const onPageClick = (path) => {
    handleCloseNavMenu(null);
    navigate(path);
  };

  return (
    <AppBar
      sx={{
        padding: 0,
        backgroundColor: "white!important",
        boxShadow: "none",
      }}
      position="static"
    >
      <Container sx={{ padding: 0 }} maxWidth="xl">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <img height={100} src="/logo192.png" alt="logo" />
          <Typography sx={{ fontWeight: "bold" }} color="primary" variant="h6">
            البلدية الإلكترونية
          </Typography>
        </Stack>
        <Toolbar disableGutters>
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton size="large" onClick={handleOpenNavMenu}>
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {getPages(userDetails.isAuthenticated).map((page) => (
                  <MenuItem
                    key={page.title}
                    onClick={() => onPageClick(page.path)}
                  >
                    <Typography variant="h6">{page.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{ gap: 3, flexGrow: 1, display: { xs: "none", md: "flex" } }}
            >
              {getPages(userDetails.isAuthenticated).map((page) => (
                <Button
                  key={page.title}
                  onClick={() => onPageClick(page.path)}
                  sx={{ my: 2, color: "primary.main", display: "block" }}
                >
                  <Typography variant="h6">{page.title}</Typography>
                </Button>
              ))}
            </Box>
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "end",
                gap: 3,
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
              }}
            >
              {/* <Chip onClick={handleClick}
                              sx={{backgroundColor: "#F5F5F5"}}
                               size="large"
                              label={<Typography color="primary.main">English</Typography>}
                              variant="outlined" /> */}
              {userDetails.isAuthenticated ? (
                <Chip
                  onClick={handleClick}
                  sx={{ pr: 2, backgroundColor: "#F5F5F5" }}
                  size="large"
                  icon={<PersonIcon color="primary" />}
                  label={
                    <Typography color="primary.main">
                      {userDetails?.citizen?.firstName}{" "}
                      {userDetails?.citizen?.familyName}
                    </Typography>
                  }
                  variant="outlined"
                />
              ) : (
                <Chip
                  onClick={() => {
                    navigate("/login");
                  }}
                  icon={<PersonIcon color="primary" />}
                  sx={{ pr: 2, backgroundColor: "#F5F5F5" }}
                  size="large"
                  label={
                    <Typography color="primary.main">تسجيل الدخول</Typography>
                  }
                  variant="outlined"
                />
              )}
              <SettingsMenu
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
              />
            </Box>
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "end",
                gap: 3,
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
              }}
            >
              {userDetails.isAuthenticated ? (
                <Chip
                  onClick={handleClick}
                  sx={{ pr: 2 }}
                  size="large"
                  icon={<PersonIcon color="primary" />}
                  label={
                    <Typography sx={{ fontWeight: "bold" }}>
                      {userDetails?.citizen?.firstName}{" "}
                      {userDetails?.citizen?.familyName}
                    </Typography>
                  }
                  variant="outlined"
                />
              ) : (
                <Chip
                  onClick={() => {
                    navigate("/login");
                  }}
                  icon={<PersonIcon color="primary" />}
                  sx={{ pr: 2, backgroundColor: "#F5F5F5" }}
                  size="large"
                  label={
                    <Typography color="primary.main">تسجيل الدخول</Typography>
                  }
                  variant="outlined"
                />
              )}
              <SettingsMenu
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
              />
            </Box>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveNavBar;
