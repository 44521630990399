import Dialog from "@mui/material/Dialog";
import {
  Box,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";

const imageExtensions = [
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".bmp",
  ".tiff",
  ".tif",
  ".webp",
  ".svg",
  ".ico",
  ".heic",
  ".heif",
  ".raw",
  ".cr2",
  ".nef",
  ".orf",
  ".sr2",
];

const containsImageExtension = (filename, allowedImagesExtensions) => {
  return allowedImagesExtensions.some((ext) =>
    filename.toLowerCase().endsWith(ext)
  );
};

const PreviewAttachmentDialog = ({
  open,
  handleClose,
  attachmentDetails,
  providedImagesExtensions,
}) => {
  const { fileObject } = attachmentDetails;

  const fileName = fileObject.name;

  // Determine whether the attachment is an image or a PDF
  const isImageAttachment = containsImageExtension(
    fileName,
    providedImagesExtensions || imageExtensions
  );
  const isPdfAttachment = fileName.toLowerCase().endsWith(".pdf");

  const handleDownloadClick = () => {
    const url = URL.createObjectURL(fileObject);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    if (fileObject) {
      URL.revokeObjectURL(url);
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{ justifyContent: "space-between", display: "flex" }}
        id="customized-dialog-title"
      >
        <Typography
          component="div"
          sx={{ fontWeight: "bold", fontSize: "1.25rem" }}
        >
          Attachment Preview
        </Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Stack justifyContent="center" alignItems="center">
          {isImageAttachment ? (
            <img
              src={URL.createObjectURL(fileObject)}
              style={{ maxWidth: 500, maxHeight: 500 }}
              alt="preview"
            />
          ) : isPdfAttachment ? (
            <embed
              src={URL.createObjectURL(fileObject)}
              type="application/pdf"
              width="100%"
              height="500px"
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack direction={"row"} gap={3}>
                <Typography>File Name:</Typography>
                <Typography component="div">{fileName}</Typography>
              </Stack>
              <IconButton onClick={handleDownloadClick}>
                <DownloadIcon color={"primary"} />
              </IconButton>
            </Box>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewAttachmentDialog;
