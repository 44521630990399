import React, { useEffect, useState } from "react";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import GppGoodIcon from "@mui/icons-material/GppGood";
import AdbIcon from "@mui/icons-material/Adb";
import AppleIcon from "@mui/icons-material/Apple";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import { isMobile } from "react-device-detect";
import IconButton from "@mui/material/IconButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useContext } from "react";
import { ConfigLinksDetailsContext } from "../Context/cofingLinksDetailsContext";
import { useNavigate } from "react-router";

const AppFooter = () => {
  const { configLinks } = useContext(ConfigLinksDetailsContext);
  const navigate = useNavigate();

  const onClick = (link) => {
    link &&
      window.open(
        link,
        "_blank" // <- This is what makes it open in a new window.
      );
  };
  return (
    <footer
      style={{
        marginTop: "100px",
        position: isMobile ? "static" : "absolute",
        bottom: 0,
        top: "100%",
        left: 0,
        right: 0,
      }}
    >
      <Grid spacing={3} container>
        <Grid
          sx={{
            justifyContent: { md: "center", xs: "start" },
            display: "flex",
          }}
          md={4}
          xs={12}
          item
        >
          <Stack gap={1}>
            <Stack>
              <Typography sx={{ fontWeight: "bold" }} color="primary.main">
                سياسة أحكام وشروط الموقع
              </Typography>
              <Divider sx={{ width: "150px" }} />
            </Stack>
            <Stack
              alignItems="center"
              gap={1}
              direction="row"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/terms-and-conditions")}
            >
              <ArticleIcon color="primary" />

              <Typography>الشروط والأحكام</Typography>
            </Stack>
            <Stack alignItems="center" gap={1} direction="row"  sx={{ cursor: "pointer" }} onClick={() => navigate("/privacy-and-protection")}>
              <GppGoodIcon color="primary" />

              <Typography>سياسة الخصوصية والحماية</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          md={4}
          xs={12}
          sx={{
            justifyContent: { md: "center", xs: "start" },
            display: "flex",
          }}
          item
        >
          <Stack gap={1}>
            <Stack>
              <Typography sx={{ fontWeight: "bold" }} color="primary.main">
                حمل التطبيق
              </Typography>
              <Divider sx={{ width: "150px" }} />
            </Stack>
            <Stack gap={2} direction="row">
              <IconButton onClick={() => onClick(configLinks.android)}>
                <AdbIcon color="primary" />
              </IconButton>
              <IconButton onClick={() => onClick(configLinks.appStore)}>
                <AppleIcon color="primary" />
              </IconButton>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          md={4}
          xs={12}
          sx={{
            justifyContent: { md: "center", xs: "start" },
            display: "flex",
          }}
          item
        >
          <Stack gap={1}>
            <Stack>
              <Typography sx={{ fontWeight: "bold" }} color="primary.main">
                مواقع التواصل
              </Typography>
              <Divider sx={{ width: "150px" }} />
            </Stack>
            <Stack gap={2} direction="row">
              <IconButton onClick={() => onClick(configLinks.facebook)}>
                <FacebookIcon color="primary" />
              </IconButton>
              <IconButton onClick={() => onClick(configLinks.whatsapp)}>
                <WhatsAppIcon color="primary" />
              </IconButton>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </footer>
  );
};
export default AppFooter;
