import {
    InputAdornment,
    Stack,
    TextField,
    Typography,
    Box,
    Link,
    Switch,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import React, {useContext, useState} from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import {useForm, Controller} from "react-hook-form";
import axios from "axios";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {LoadingButton} from '@mui/lab';
import {passwordValidationPattern} from "../../constants/index"



const ResetPasswordForm = () => {
    const [isLoading, setIsLoading] = useState(false);

    const {
        handleSubmit,
        control,
        setError,
        formState: {errors},
        watch
    } = useForm({
        mode: "onChange",
    });
    const {password} = watch();
    const [showPassword, setShowPassword] = useState(false);
    const onPasswordClicked = () => {
        setShowPassword((prevState) => !prevState);
    };
    const {userId, code} = useParams();
    const nav = useNavigate();
    const onSubmit = (data) => {
        setIsLoading(true);
        axios.post(`/Auth/ResetPassword`, {userId, code, newPassword: data.password}).then(res => {
            setIsLoading(false);
            nav(`/login`);
        }).catch(e => {
            setIsLoading(false);
            setError("confirmPassword", {message: e.response.data.message});
        });
    }
    return (
        <Stack sx={{p: 3, width: "90%", gap: 5}}>
            <Typography
                sx={{fontWeight: "bold", alignSelf: "center"}}
                color="primary.main"
                variant="h5"
            >
                تغيير كلمة المرور
            </Typography>
            <Stack>
                <Typography sx={{fontWeight: "bold"}}>كلمة المرور</Typography>
                <Controller
                    name="password"
                    control={control}
                    rules={{
                        required: "يجب ادخال كلمة المرور",

                        pattern: {
                            value: passwordValidationPattern,
                            message: 'كلمة المرور يجب ان تحتوي على الاقل على 8 خانات, حروف كبيرة و صغيرة وأرقام'
                        }
                    }}
                    render={({field}) => (
                        <TextField
                            {...field}
                            type={showPassword ? "text" : "password"}
                            variant="standard"
                            inputProps={{
                                style: {
                                    textAlign: "center",
                                    height: 37,
                                    borderBottomRightRadius: 0,
                                    borderTopRightRadius: 0,
                                },
                            }}
                            InputProps={{
                                disableUnderline: true,
                                placeholder: "ادخل كلمة المرور",
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box
                                            sx={{
                                                backgroundColor: "#EAF0F7",
                                                p: 0.5,
                                                borderBottomRightRadius: 10,
                                                borderTopRightRadius: 10,
                                            }}
                                        >
                                            <IconButton onClick={onPasswordClicked}>
                                                {showPassword ? (
                                                    <VisibilityOffIcon color="primary"/>
                                                ) : (
                                                    <VisibilityIcon color="primary"/>
                                                )}
                                            </IconButton>
                                        </Box>
                                    </InputAdornment>
                                ),
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    textAlign: "start",
                                },
                            }}
                            error={!!errors.password}
                            helperText={errors.password ? errors.password.message : ""}
                        />
                    )}
                />
            </Stack>
            <Stack>
                <Typography sx={{fontWeight: "bold"}}>تأكيد كلمة المرور</Typography>
                <Controller
                    name="confirmPassword"
                    control={control}
                    rules={{
                        required: "يجب ادخال تأكيد كلمة المرور",
                        validate: (value) => value === password ? true : "كلمة المرور غير مطابقة"
                    }}
                    render={({field}) => (
                        <TextField
                            {...field}
                            type={showPassword ? "text" : "password"}
                            variant="standard"
                            inputProps={{
                                style: {
                                    textAlign: "center",
                                    height: 37,
                                    borderBottomRightRadius: 0,
                                    borderTopRightRadius: 0,
                                },
                            }}
                            InputProps={{
                                disableUnderline: true,
                                placeholder: "ادخل تأكيد كلمة المرور",
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box
                                            sx={{
                                                backgroundColor: "#EAF0F7",
                                                p: 0.5,
                                                borderBottomRightRadius: 10,
                                                borderTopRightRadius: 10,
                                            }}
                                        >
                                            <IconButton onClick={onPasswordClicked}>
                                                {showPassword ? (
                                                    <VisibilityOffIcon color="primary"/>
                                                ) : (
                                                    <VisibilityIcon color="primary"/>
                                                )}
                                            </IconButton>
                                        </Box>
                                    </InputAdornment>
                                ),
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    textAlign: "start",
                                },
                            }}
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword ? errors.confirmPassword.message : ""}
                        />
                    )}
                />
            </Stack>

            <LoadingButton
                type="sumbit"
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
                sx={{width: "80%", alignSelf: "center"}}
                variant="contained"
            >
                <Typography>تغيير كلمة المرور</Typography>
            </LoadingButton>
        </Stack>
    );
};
export default ResetPasswordForm;
