import React from "react";
import { Box, Typography, Grid, TextField, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import { isMobile } from "react-device-detect";

const GeneralComponent = ({ categories, control, withCategories, errors }) => {
  return (
    <>
      <Box
        sx={{
          background: "#fff",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
          width: isMobile ? "unset" : 300,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          تفاصيل الطلب
        </Typography>
      </Box>
      <Grid
        sx={{ background: "#fff", p: 2, mt: 1, width: "100%" }}
        spacing={withCategories ? 4 : 0}
        container
      >
        {withCategories && (
          <Grid item xs={12} md={4}>
            <Typography sx={{ fontWeight: "bold" }}>تصنيف الطلب</Typography>

            <Controller
              name="category"
              control={control}
              defaultValue="" // Add default value to prevent undefined
              rules={{
                required: "هذا الحقل مطلوب",
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    placeholder: "",
                  }}
                  value={field.value || ""} // Ensure value is not undefined
                  FormHelperTextProps={{
                    sx: {
                      textAlign: "start",
                    },
                  }}
                  error={!!errors.category}
                  helperText={
                    errors.category ? errors.category.message : ""
                  }
                >
                  {categories.map((option) => (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} md={withCategories ? 8 : 12}>
          <Typography sx={{ fontWeight: "bold" }}>التفاصيل</Typography>
          <Controller
            name="details"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                multiline
                variant="outlined"
                InputProps={{
                  placeholder: "",
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GeneralComponent;
