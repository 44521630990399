import { Button, Dialog, Stack, Typography } from "@mui/material";

const MessageModal = ({openModal, interfaceIcon, message, onCloseModal, buttonText}) => {
  return (
    <Dialog
      open={openModal}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          border: "1px solid #BC1F41",
          borderRadius: 3,
          padding: 3,
        },
      }}
    >
      <Stack justifyContent={"center"} alignItems={"center"}>
        {interfaceIcon}
        <Typography sx={{ fontWeight: "bold" }} variant="h6">
            {message}
        </Typography>
        <Button
          type="sumbit"
          sx={{ width: "30%", alignSelf: "center", marginTop: 4 }}
          variant="contained"
          onClick={onCloseModal}
        >
          <Typography>{buttonText}</Typography>
        </Button>
      </Stack>
    </Dialog>
  );
};
export default MessageModal;
