import AppRoutes from "../AppRoutes";
import { useContext, useEffect, useState } from "react";
import axiosInstance from '../axiosConfig';
import { UserDetailsContext } from "../Context/userDetailsContext";
import { Box, CircularProgress } from "@mui/material";

const MainApp = () => {
    const [loadApp, setLoadApp] = useState(true);
    const { setUserDetails } = useContext(UserDetailsContext);

    useEffect(() => {
        axiosInstance.get("/User/Info").then(res => {
            setUserDetails({ ...res.data.dataItem, isAuthenticated: true });
            setLoadApp(false);
        }).catch(() => {
            setLoadApp(false);
        });
    }, [setUserDetails]);

    if (loadApp) {
        return (
            <Box sx={{ mt: 25, textAlign: "center" }}>
                <CircularProgress size={80} />
            </Box>
        );
    }

    return <AppRoutes />;
};

export default MainApp;
