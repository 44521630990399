import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DataBoard from "../Components/DataBoard/index";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { isMobile } from "react-device-detect";

const MainPage = () => {
  const [appicationsData, setApplicationsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const getApplicationsData = async () => {
      try {
        const data = await axios.get("/application");
        setIsLoading(false);
        setApplicationsData(data);
      } catch (e) {
        setIsLoading(false);
      }
    };
    getApplicationsData();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ mt: 5, textAlign: "center" }}>
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: isMobile ? 1 : 3,
      }}
    >
      <Box sx={{ width: "100%" }}>
        <DataBoard appicationsData={appicationsData} />
      </Box>
    </Box>
  );
};

export default MainPage;
