import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation, useNavigate } from "react-router-dom";
import { Paper, Stack } from "@mui/material";
import { getApplicationsCount } from "../../utils/applicationsTypesCounter";
import { convertTimeFormat } from "../../utils/timeFormatter";
import IconButton from "@mui/material/IconButton";
import { CircularProgress } from "@mui/material";

const MobileMyOrders = ({ appicationsData, isLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const applicationsTypes = [
    {
      title: "طلب جديد",
      color: "white",
      id: 1,
    },
    {
      title: "طلب قيد التنفيذ",
      color: "#FFE18C",
      id: 2,
    },
    {
      title: "طلب معلق",
      color: "#65E0FF",
      id: 3,
    },
    {
      title: "طلب مكتمل",
      color: "#72EF9B",
      id: 4,
    },
    {
      title: "طلب مرفوض",
      color: "#FF3A3A",
      id: 5,
    },
    {
      title: "ملغى",
      color: "#D1D1D1",
      id: 6,
    },
  ];

  const handleViewClick = (item) => {
    navigate(`/order-details/${item.subscriberServiceId}`, {
      state: { ...location?.state, groupName: item.serviceName },
    });
  };

  if (isLoading) {
    return (
      <Box sx={{ mt: 5, textAlign: "center" }}>
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          my: 3,
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {applicationsTypes.map((application) => {
          const applicationCount = getApplicationsCount(
            appicationsData,
            application.id
          );
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                flexDirection: "column",
              }}
            >
              <Paper
                elevation={2}
                sx={{
                  height: 40,
                  width: 40,
                  borderRadius: "50%",
                  backgroundColor: application.color,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant={"caption"}>{applicationCount}</Typography>
              </Paper>
              <Typography variant={"caption"}>{application.title}</Typography>
            </Box>
          );
        })}
      </Box>
      <Stack gap={2}>
        {appicationsData?.map((t) => {
          return (
            <Paper sx={{ p: 2 }} elevation={5}>
              <Stack gap={2}>
                <Box display="flex" gap={2}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    رقم الطلب:
                  </Typography>
                  <Typography>{t.subscriberServiceFullNumber}</Typography>
                </Box>
                <Box display="flex" gap={2}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    نوع الطلب:
                  </Typography>
                  <Typography>{t.serviceName}</Typography>
                </Box>
                <Box display="flex" gap={2}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    رسوم الطلب:
                  </Typography>
                  <Typography>
                    {t.applicationFee}
                    {t.currencyChar3Code}
                  </Typography>
                </Box>
                <Box display="flex" gap={2}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    تاريخ الطلب:
                  </Typography>
                  <Typography sx={{ direction: "ltr", textAlign: "end" }}>
                    {convertTimeFormat(t.insertDate)}
                  </Typography>
                </Box>
                <Box display="flex" gap={2}>
                  <Typography sx={{ fontWeight: "bold" }}>النشاط:</Typography>
                  <Typography>{t.activityName}</Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={2}>
                  <Typography sx={{ fontWeight: "bold" }}>مراجعة:</Typography>
                  <IconButton onClick={() => handleViewClick(t)}>
                    <VisibilityIcon />
                  </IconButton>
                  <Typography></Typography>
                </Box>
              </Stack>
            </Paper>
          );
        })}
      </Stack>
    </Box>
  );
};

export default MobileMyOrders;
