import {
  Grid,
  Paper,
  Typography,
  Stack,
  Divider,
  Box,
  CircularProgress,
} from "@mui/material";
import OrderDetailsForm from "../Components/OrderDetailsForm/index";
import { useLocation, useParams } from "react-router";
import { useEffect, useState } from "react";
import axios from "axios";

const OrderDetails = () => {
 

  return (
    <Box
      sx={{
        padding: 3,
      }}
    >
      <Box sx={{ marginBottom: 4 }}></Box>
      <OrderDetailsForm
       
      />
    </Box>
  );
};
export default OrderDetails;
