import React, { useEffect, useState } from "react";
import MyServicesContent from "../Components/MyServices/index";
import axios from "axios";

const MyServices = () => {
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const getServicesData = async () => {
      try {
        const data = await axios.get("/user/services");
        setServices(data);
        setIsLoading(false);
      } catch (e) {
        console.log("e", e);
      }
    };
    getServicesData();
  }, []);

  return <MyServicesContent services={services} isLoading={isLoading} />;
};

export default MyServices;
