import React, { useEffect, useState } from "react";
import MyNotificationsContent from "../Components/MyNotifactions/index";
import axios from "axios";

const MyNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const getNotificationsData = async () => {
      try {
        const data = await axios.get("/user/notifications");
        setNotifications(data);
        setIsLoading(false);
      } catch (e) {
        console.log("e", e);
      }
    };
    getNotificationsData();
  }, []);

  return (
    <MyNotificationsContent
      notifications={notifications}
      isLoading={isLoading}
    />
  );
};

export default MyNotifications;
