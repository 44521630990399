import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Paper, Stack, Divider } from "@mui/material";
import { convertTimeFormat } from "../../utils/timeFormatter";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DOMPurify from 'dompurify';
import {CircularProgress} from "@mui/material";
import IconButton from "@mui/material/IconButton";


const MobileMyNotifications = ({notifications, isLoading}) => {
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogData, setDialogData] = useState({
        sentBy: "",
        time: "",
        message: "",
    });

    const handleCloseDialog = () => {
        setDialogData({});
        setOpenDialog(false);
    };

    const handleViewMessageClick = ({ message, sentBy, time }) => {
        setDialogData({ sentBy, message, time });
        setOpenDialog(true);
    };

    const handleOrderNumberClick = (id) => {
        navigate(`/order-details/${id}`);
    };

    if (isLoading) {
        return (
            <Box sx={{ textAlign: "center" }}>
                <CircularProgress size={80} />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                width: "100%",
                "& .table-header": {
                    backgroundColor: "#DFE7F2",
                    color: "#BF3C4E",
                    border: "1px solid white",
                },
                "& .MuiDataGrid-columnHeaders": {
                    overflowX: { xs: "scroll", md: "hidden" },
                },
                "& .MuiDataGrid-footerContainer": {
                    direction: "ltr",
                },
                "& .MuiDataGrid-row:hover": {
                    backgroundColor: "#d9e0eb",
                },
                padding: 2
            }}
        >
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                sx={{
                    "& .MuiDialog-paper": {
                        border: "2px solid #BF3C4E", // Set the border color here
                        padding: "20px",
                        borderRadius: "10px",
                    },
                }}
            >
                <Stack gap={3} sx={{ marginBottom: 5 }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            gap: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: "bold" }}>ارسل بواسطة :</Typography>
                        <Typography>{dialogData.sentBy}</Typography>
                    </Box>
                    <Divider sx={{ width: "100%" }} />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            gap: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: "bold" }}>وقت الارسال :</Typography>
                        <Typography>{dialogData.time}</Typography>
                    </Box>
                    <Divider sx={{ width: "100%" }} />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            gap: 1,
                            alignItems:{xs: "start", md: "center"}
                        }}
                    >
                        <Typography sx={{ fontWeight: "bold" }}>نص&nbsp;الرسالة:</Typography>
                        <Typography dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dialogData.message) }}></Typography>
                    </Box>
                </Stack>
                <Button
                    type="sumbit"
                    sx={{ width: "30%", alignSelf: "center" }}
                    variant="contained"
                    onClick={handleCloseDialog}
                >
                    <Typography>اغلاق</Typography>
                </Button>
            </Dialog>
            <Stack gap={2}>
                {
                    notifications?.map(t=> {
                        return (
                            <Paper sx={{p:2}} elevation={5}>
                                <Stack gap={2}>
                                    <Box display="flex" gap={2}>
                                        <Typography sx={{fontWeight: "bold"}}>وقت الإرسال:</Typography>
                                        <Typography sx={{direction: "ltr", textAlign: "end"}}>{convertTimeFormat(t.timestamp)}</Typography>
                                    </Box>
                                    <Box display="flex" gap={2}>
                                        <Typography sx={{fontWeight: "bold"}}>نوع الاشعار:</Typography>
                                        <Typography>{t.type.name}</Typography>
                                    </Box>
                                    <Box display="flex" gap={2}>
                                        <Typography sx={{fontWeight: "bold"}}>ارسل الى:</Typography>
                                        <Typography>{t.sentToName}</Typography>
                                    </Box>
                                    <Box display="flex" gap={2}>
                                        <Typography sx={{fontWeight: "bold"}}>معلومات الارسال:</Typography>
                                        <Typography title={t.sentTo} sx={{ whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",}}>{t.sentTo}</Typography>
                                    </Box>

                                    <Box display="flex" alignItems="center" gap={2}>
                                        <Typography sx={{fontWeight: "bold"}}>رقم الطلب:</Typography>
                                        {t.subscriberServiceFullNumber && <IconButton onClick={()=> handleOrderNumberClick(t.subscriberServiceId)}>
                                            <OpenInNewIcon />
                                        </IconButton>}
                                    </Box>
                                    <Box display="flex" alignItems="center" gap={2}>
                                        <Typography sx={{fontWeight: "bold"}}>الرسالة:</Typography>
                                        <IconButton onClick={()=> handleViewMessageClick({
                                            message: t.message,
                                            sentBy: t.sentByUser,
                                            time: convertTimeFormat(t.timestamp),
                                        })}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Box>

                                </Stack>
                            </Paper>
                        )
                    })
                }
            </Stack>
        </Box>
    );
};

export default MobileMyNotifications;
