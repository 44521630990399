import { Paper, Stack, Typography } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";

const ServiceOrderCard = ({
  name,
  groupName,
  pageName,
  applicationFee,
  currency,
  ...rest
}) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(pageName, {
      state: { groupName, name, applicationFee, currency, ...rest },
    });
  };
    
  return (
    <Paper sx={{ cursor: "pointer" }} onClick={onClick} elevation={2}>
      <Stack
        sx={{ p: 2.5, gap: 4 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" alignItems="center">
          <SettingsIcon color="primary" />
          <Typography sx={{ fontWeight: "bold", marginRight: 1 }}>
            {name}
          </Typography>
        </Stack>
        <Typography
          sx={{ fontWeight: "bold" }}
          color="primary"
        >{`${applicationFee} ${currency?.[0]?.code}`}</Typography>
      </Stack>
    </Paper>
  );
};
export default ServiceOrderCard;
