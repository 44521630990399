import {Controller, useForm} from "react-hook-form";
import {Box, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import {useParams} from "react-router";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import { LoadingButton } from '@mui/lab';

const CheckCodeForm = ()=>{
    const [isLoading, setIsLoading] = useState(false);
    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
    } = useForm({
        mode: "onChange",
    });
    const {userId, code} = useParams();
    const nav = useNavigate();
    const onSubmit = (data) =>{
        setIsLoading(true);
        axios.post(`/Auth/CheckCode`, {userId, code: data.code}).then(res=> {
            setIsLoading(false);
            nav(`/reset-password/${userId}/${data.code}`);
        }).catch(e=>{
            setError("code", {message: e.response.data.message})
            setIsLoading(false)
        });
    }
    return (
            <Stack sx={{p: 3, width: "90%", gap: 10  }}>
                <Typography
                    sx={{fontWeight: "bold", alignSelf: "center"}}
                    color="primary.main"
                    variant="h5"
                >
                    ادخال الرمز
                </Typography>
                <Stack>
                    <Typography sx={{fontWeight: "bold"}}>الرمز</Typography>
                    <Controller
                        name="code"
                        control={control}
                        rules={{
                            required: "يجب ادخال الرمز",

                        }}
                        render={({field}) => (
                            <TextField
                                {...field}
                                variant="standard"
                                inputProps={{
                                    style: {
                                        textAlign: "center",
                                        height: 37,
                                        borderBottomRightRadius: 0,
                                        borderTopRightRadius: 0
                                    },
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    placeholder: "ادخل الرمز",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    width: 48,
                                                    height: 48,
                                                    backgroundColor: "#EAF0F7",
                                                    borderBottomRightRadius: 10,
                                                    borderTopRightRadius: 10,
                                                }}
                                            >
                                                <PersonIcon color="primary"/>
                                            </Box>
                                        </InputAdornment>
                                    ),
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        textAlign: "start",
                                    },
                                }}
                                error={!!errors.code}
                                helperText={errors.code ? errors.code.message : ""}
                            />
                        )}
                    />

                </Stack>

                    <Typography
                        variant="caption"
                        sx={{fontWeight: "bold", alignSelf: "center"}}
                    >
                        ادخل الرمز المرسل إليك عبر رسالة نصية على هاتفك
                    </Typography>
                <LoadingButton
                    type="sumbit"
                    loading={isLoading}
                    sx={{width: "80%", alignSelf: "center"}}
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                >
                    <Typography>اعادة ضبط كلمة المرور</Typography>
                </LoadingButton>
            </Stack>
    );
}
export default CheckCodeForm;