import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n.js";
import "./axiosConfig.js";
import { Workbox } from "workbox-window";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Register Workbox service worker
if ('serviceWorker' in navigator) {
  const wb = new Workbox(`${process.env.PUBLIC_URL}/service-worker.js`);

  wb.addEventListener('installed', (event) => {
    if (event.isUpdate) {
      // New content is available, tell the user
      if (window.confirm("New update available! Do you want to refresh?")) {
        window.location.reload();
      }
    } else {
      console.log("Content is cached for offline use.");
    }
  });

  wb.register().catch((error) => {
    console.error("Error during service worker registration:", error);
  });
}
