import Box from "@mui/material/Box";
import { Grid, Typography, Stack, Divider } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";

const GeneralWithCategoriesForm = ({
  generalServices,
  isGeneralCategoryForm,
}) => {
  return (
    <>
      <Box
        sx={{
          background: "#fff",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
          width: isMobile ? "unset" : 300,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {" "}
          تفاصيل الطلب{" "}
        </Typography>
      </Box>
      <Grid
        sx={{ backgroundColor: "#fff", mt: 2, p: 2, width: "100%" }}
        spacing={isMobile ? 3 : 0}
        container
      >
        {isGeneralCategoryForm && (
          <Grid item md={3} sm={4} xs={12}>
            <Stack direction={"row"} gap={1}>
              <Typography sx={{ fontWeight: "bold" }}> تصنيف الطلب:</Typography>
              <Typography>{generalServices?.generalCategory?.name}</Typography>
            </Stack>
          </Grid>
        )}
        <Grid item md={3} sm={4} xs={12}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{ fontWeight: "bold" }}> التفاصيل:</Typography>
            <Typography>{generalServices.notes} </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default GeneralWithCategoriesForm;
