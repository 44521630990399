import {createTheme} from "@mui/material";

const theme = createTheme({
    typography: {
        fontFamily: 'Dubai, Arial, sans-serif',
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& input": {
                        filter: "drop-shadow(0px 3px 3px rgba(0,0,0,0.16 ))",
                        border: "1px solid #E4E9EF!important",
                        borderRadius: 7
                    },
                    '& .MuiInputBase-root': {
                        backgroundColor: '#fff', // Change this to your desired color
},
                },
            },
        },
    },
    palette: {
        primary: {
            main: "#BC1F41"
        },
    },
});

export default theme;