import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import React, { useState } from "react";

const DesktopServiceGuideDetailsItem = () => {
  const location = useLocation();
  const nav = useNavigate();

  if (!location.state) {
    nav("/services-guide");
    return null; // Ensure the component doesn't render if navigation occurs
  }

  const currencyName = location.state?.currency?.[0]?.name || "";
  const {
    applicationFee,
    compilationTime,
    isPdfExist,
    requiredAttachments,
    pdfPath,
  } = location.state;


  const openPdf = () => {
    window.open(pdfPath, "_blank");
  };

  return (
    <Paper sx={{ p: 2 }} square>
      <Stack>
        <Box display="flex" gap={10} justifyContent="flex-start">
          <Typography sx={{ fontWeight: "bold" }}>
            رسوم الطلب: {applicationFee} {currencyName}
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            {`الوقت اللازم لإنجاز الطلب: ${
              compilationTime ? `${compilationTime} ايام` : ""
            }`}
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"start"}
            gap={1}
            sx={{
              "& .MuiButtonBase-root": {
                padding: 0,
              },
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>دليل الخدمة :</Typography>
            <IconButton
              color={"primary"}
              disabled={!isPdfExist}
              onClick={openPdf}
            >
              <PictureAsPdfIcon sx={{ padding: 0 }} />
            </IconButton>
          </Box>
        </Box>
        <Grid sx={{ backgroundColor: "primary.main", p: 2, mt: 2 }} container>
          <Grid textAlign="right" color="#fff" md={4} item>
            <Typography sx={{ fontWeight: "bold" }}>نوع المرفق</Typography>
          </Grid>
          <Grid textAlign="center" color="#fff" md={4} item>
            <Typography sx={{ fontWeight: "bold" }}>اجباري</Typography>
          </Grid>
          <Grid textAlign="center" color="#fff" md={4} item>
            <Typography sx={{ fontWeight: "bold" }}>اختياري</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ p: 2 }}>
          {requiredAttachments.map((t, i) => (
            <React.Fragment key={i}>
              <Grid textAlign="right" md={4} item>
                <Typography sx={{ fontWeight: "bold" }}>
                  {t.attachmentType}
                </Typography>
              </Grid>
              <Grid textAlign="center" md={4} item>
                {t.isMandatory ? (
                  <CheckCircleOutlineOutlinedIcon htmlColor="#32CE37" />
                ) : null}
              </Grid>
              <Grid textAlign="center" md={4} item>
                {!t.isMandatory ? (
                  <CheckCircleOutlineOutlinedIcon htmlColor="#32CE37" />
                ) : null}
              </Grid>
              <Grid md={12} item>
                {requiredAttachments.length - 1 !== i && (
                  <Divider sx={{ backgroundColor: "primary.main" }} />
                )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Stack>
    </Paper>
  );
};

export default DesktopServiceGuideDetailsItem;
