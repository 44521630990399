export const filePathToFile = async (relativeFilePath, fileName) => {
  const basePath = getBasePath();
  const fullPath = `${basePath}/${relativeFilePath}`;

  // Fetch the file content from the full file path (URL)
  const response = await fetch(fullPath);

  // Convert the response to a Blob
  const blob = await response.blob();

  // Create a File object from the Blob
  const file = new File([blob], fileName, { type: blob.type });

  return file;
};

  const getBasePath = () => {
    return `${window.location.origin}`;
};