import { Divider, Grid, Paper, Stack } from "@mui/material";
import React from "react";
import CreateUser from "../Components/CreateUser";
import ForgetPasswordForm from "../Components/ForgetPasswordForm";
import { isMobile } from "react-device-detect";
import config from "../config";

const ForgetPassword = () => {
  const showRegForm = config.showRegistrationForm;
  return (
    <Paper sx={{ borderRadius: "27px" }} elevation={5}>
      <Grid container>
        <Grid
          sx={
            !isMobile
              ? {
                  boxShadow: "-10px 0px 10px 0px rgba(0, 0, 0, 0,8)",
                  ...(!showRegForm ? { margin: "0 auto" } : {}),
                }
              : {}
          }
          item
          display="flex"
          justifyContent="center"
          xs={12}
          md={showRegForm ? 6 : 5}
        >
          <ForgetPasswordForm />
        </Grid>
        {showRegForm && (
          <Grid
            sx={
              isMobile
                ? { py: 4 }
                : {
                    backgroundColor: "#F4F8FC",
                    borderTopLeftRadius: "27px",
                    borderBottomRightRadius: "27px",
                  }
            }
            xs={12}
            item
            md={6}
          >
            <CreateUser />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
export default ForgetPassword;
