import React, {createContext, useEffect, useState} from 'react';
import axios from 'axios';

const ConfigLinksDetailsContext = createContext();

const ConfigLinksDetailsProvider = ({ children }) => {
  const [configLinks, setConfigLinks] = useState({});
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const configData = await axios.get("/settings");
        setConfigLinks(configData);
      } catch (err) {
        //setError(err.message);
      }
    };

    fetchConfig();
  }, []);
  return (
    <ConfigLinksDetailsContext.Provider value={{ configLinks, setConfigLinks }}>
      {children}
    </ConfigLinksDetailsContext.Provider>
  );
};

export { ConfigLinksDetailsProvider, ConfigLinksDetailsContext };
