import React, {useContext} from 'react';
import { Route, Navigate  } from 'react-router-dom';
import {UserDetailsContext} from "../Context/userDetailsContext";

const ProtectedRoute = ({ children }) => {
 const {userDetails} = useContext(UserDetailsContext);
    return userDetails.isAuthenticated ? children : <Navigate to="/login" />;

};

export default ProtectedRoute;
