import React, { useContext, useState } from "react";
import { Grid, Stack, Typography, Box } from "@mui/material";
import { UserDetailsContext } from "../Context/userDetailsContext";
import RequestServiceForm from "../Components/RequestServiceForm/index";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router";

const RequestService = () => {
  const { userDetails } = useContext(UserDetailsContext);
  const location = useLocation();
  const { state } = location;

  return (
    <Stack gap={2} sx={{ padding: 3 }}>
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent={"space-between"}
        gap={2}
      >
        <Box
          sx={{
            background: "#fff",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 1,
            width: isMobile ? "unset" : 300,
          }}
        >
          <Typography variant="h6">بيانات مقدم الطلب</Typography>
        </Box>
        <Box
          sx={{
            background: "#fff",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 1,
            width: isMobile ? "unset" : 300,
          }}
        >
          <Typography variant="h6">{`${state?.applicationFee} ${state?.currency?.[0]?.name}`}</Typography>
        </Box>
      </Stack>

      <Grid
        sx={{ backgroundColor: "#fff", mt: 2, p: 2, width: "100%" }}
        spacing={2}
        container
      >
        <Grid item sm={6} lg={3} md={4} xs={12}>
          <Stack direction="row" gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>اسم مقدم الطلب:</Typography>
            <Typography>{userDetails?.citizen?.fullName}</Typography>
          </Stack>
        </Grid>
        <Grid item sm={6} lg={3} md={4} xs={12}>
          <Stack direction="row" gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>نوع الوثيقة:</Typography>
            <Typography>{userDetails?.citizen?.documentType.textA}</Typography>
          </Stack>
        </Grid>
        <Grid item sm={6} lg={3} md={4} xs={12}>
          <Stack direction="row" gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>رقم الوثيقة:</Typography>
            <Typography>{userDetails?.citizen?.documentNumber}</Typography>
          </Stack>
        </Grid>

        <Grid item sm={6} lg={3} md={4} xs={12}>
          <Stack direction="row" gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>رقم الموبايل:</Typography>
            <Typography>{userDetails?.citizen?.phone1}</Typography>
          </Stack>
        </Grid>

        <Grid item md={6} xs={12}>
          <Stack direction="row" gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>
              البريد الإلكتروني:
            </Typography>
            <Typography
              title={userDetails?.citizen?.email}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {userDetails?.citizen?.email}
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={6} xs={12}>
          <Stack direction="row" gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>العنوان:</Typography>
            <Typography>{userDetails?.citizen?.address}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <RequestServiceForm />
    </Stack>
  );
};
export default RequestService;
