import axios from 'axios';


// Create an Axios instance with a base URL
const axiosInstance = axios.create({
  baseURL: '/api'
});

// Create an Axios instance
axios.defaults.baseURL = "/api"

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    if(response.status === 200 && response.data.status !== "Success") {
      return Promise.reject({response});
    }
    // Any status code within the range of 2xx causes this function to trigger
    return response.data.isList ? response.data.dataList : response.data.dataItem  ;
  },
  (error) => {
    console.log("error", error)
    // Any status codes outside the range of 2xx cause this function to trigger
    // Handle response errors
    if (error.response && error.response.status === 401) {
      // For example, redirect to login page if 401 Unauthorized error
      window.location.href = '/login';
    }
    return Promise.reject(error?.response?.data)
  }
);
export default axiosInstance;
