import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  TextField,
  Box,
  Typography,
  Divider,
  Grid,
} from "@mui/material";
import { isMobile } from "react-device-detect";

const ClearanceCertificateForm = ({ clearanceCertificateData }) => {
  const columns = [
    { field: "subscriberCode", headerName: "رمز الاشتراك", flex: 1 },
    { field: "taxType", headerName: "نوع الضريبة", flex: 1 },
    { field: "parcelNumber", headerName: "كود الضريبة", flex: 1 },
    { field: "location", headerName: "الموقع", flex: 1 },
    { field: "beneficiaryType", headerName: "نوع المستفيد", flex: 1 },
  ];

  return (
    <>
      <Box
        sx={{
          background: "#fff",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
          width: isMobile ? "unset" : 300,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          بيان الذمة
        </Typography>
      </Box>
      <Grid
        sx={{
          backgroundColor: "#fff",
          mt: 2,
          p: 2,
          width: "100%",
          cursor: "auto",
        }}
        spacing={3}
        container
      >
        <Grid item md={12} sm={12} xs={12}>
          <TableContainer component={Paper}>
            <Box p={2} sx={{ direction: "rtl" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.field}
                        align="center"
                        style={{ textAlign: "center" }}
                      >
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clearanceCertificateData.map((item) => (
                    <TableRow key={item.subscriberNumber} selected={true} hover>
                      <TableCell align="center">
                        {item.subscriberCode}
                      </TableCell>
                      <TableCell align="center">
                        {item?.serviceType?.name}
                      </TableCell>
                      <TableCell align="center">{item.taxType}</TableCell>
                      <TableCell align="center">{item.location}</TableCell>
                      <TableCell align="center">
                        {item.beneficiaryType?.name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
        </Grid>
      </Grid>

      <Divider />
    </>
  );
};

export default ClearanceCertificateForm;
