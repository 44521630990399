import { useState, useContext, useEffect } from "react";
import { ConfigLinksDetailsContext } from "../Context/cofingLinksDetailsContext";
import axios from "axios";
import { Box, CircularProgress } from "@mui/material";
import DOMPurify from "dompurify";

const PrivacyAndProtection = () => {
  const [privacyAndProtectionData, setPrivacyAndProtectionData] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(true);
  const { configLinks } = useContext(ConfigLinksDetailsContext);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoadingData(true)
        const response = await axios.get(`CmsArticle/${configLinks.cmsCatId}?articleId=${configLinks.policyArticleId}`);
        setPrivacyAndProtectionData(response[0]);
        setIsLoadingData(false)
      } catch (e) {
        console.log("e", e);
      }
    };
    getData();
  }, []);

  if (isLoadingData) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress size={80} />
      </Box>
    );
  }

  return <Box dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(privacyAndProtectionData.articleText) }} sx={{padding: 2}}></Box>;
};

export default PrivacyAndProtection;
