
export const pages = {
    "/": "الصفحة الرئيسية",
    "/order-details": "$groupName",
    "/service-request": "$groupName - $serviceName",
    "/service-guide-details": "$groupName - $serviceName",
    "/services": "تقديم طلب / خدمة",
    "/services-guide": "دليل الخدمات",
    "/sign-up": "تسجيل مواطن جديد",
    "/my-account": "حسابي",
    "/my-orders": "طلباتي",
    "/my-notifications": "اشعاراتي",
    "/my-services": "خدماتي"
}