import {Box, Paper, Typography} from "@mui/material";
import {useLocation} from "react-router-dom";
import {pages} from "../../constants/pages";

const BreadCrumb = () => {
    const location = useLocation();
    const name = location.pathname.split("/")[1];
    let pageName = pages["/"+name];
        pageName = pageName && pageName.replace("$groupName", location.state?.groupName).replace("$serviceName", location.state?.name);
    return (
        <Paper sx={{background: "#F9FAFC"}} elevation={2} square>
        {pageName &&  <Box sx={{ p: 1.875, background: "#F9FAFC"}}>
            <Typography sx={{fontWeight: "bold"}} variant="h6">{pageName}</Typography>
        </Box>}
        </Paper>
    )
}
export default BreadCrumb;