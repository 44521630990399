import React, { useState } from "react";
import { Grid, Typography, Box, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { isMobile } from "react-device-detect";
import ServiceTypeTable from "./ServiceTypeTable";

const ServiceTypeForm = ({
  isServiceRequired,
  control,
  errors,
  setValue,
  services,
  showFromMap,
}) => {
  const [selectedItem, setSelectedItem] = useState("");
  const isFromRequired = showFromMap ? false : isServiceRequired;

  const handleSelect = (subscriberNumber) => {
    setSelectedItem(subscriberNumber);
    setValue("selectedService", subscriberNumber, {
      shouldValidate: true,
    });
  };

  return (
    <>
      <Box
        sx={{
          background: "#fff",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
          width: isMobile ? "unset" : 300,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          الخدمات المسجلة
        </Typography>
      </Box>
      <Grid
        sx={{ background: "#fff", p: 2, mt: 1, width: "100%" }}
        spacing={4}
        container
      >
        <Grid item xs={12} md={9}>
          <Controller
            name="selectedService"
            control={control}
            rules={{
              required: isFromRequired && "هذا الحقل مطلوب",
            }}
            render={({ field }) => (
              <>
                <ServiceTypeTable
                  data={services}
                  selectedItem={selectedItem}
                  onSelect={handleSelect}
                />
                <TextField
                  {...field}
                  sx={{
                    "& .MuiInputBase-root": {
                      display: "none",
                    },
                  }}
                  value={selectedItem}
                  type="hidden"
                  error={!!errors.selectedService}
                  helperText={
                    errors.selectedService ? errors.selectedService.message : ""
                  }
                />
              </>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ServiceTypeForm;
