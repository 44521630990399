import { Box, Paper, Tabs, Tab, Typography } from "@mui/material";
import React, { useEffect } from "react";
import PersonalInfo from "../Components/PersonalInfo";
import ChangePassword from "../Components/ChangePassword";
import { isMobile } from "react-device-detect";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockResetIcon from "@mui/icons-material/LockReset";

const tabs = [
  {
    id: "1",
    label: "المعلومات الشخصية",
    component: PersonalInfo,
    icon: <AccountCircleIcon color="primary" />,
  },
  {
    id: "2",
    label: "تغيير كلمة المرور",
    component: ChangePassword,
    icon: <LockResetIcon color="primary"/>,
  },
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      key={index}
      sx={{ width: "100%" }}
    >
      {value === index && (
        <Box key={index} sx={{ p: isMobile ? 1 : 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyAccount = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper sx={{ p: 3, background: "#F9FAFC" }} elevation={5}>
      <Box
        sx={{
          padding: isMobile ? 1 : 3,
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          height: "100%",
          width: "100%",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            borderRight: 1,
            borderColor: "divider",
            minWidth: 200,
            "& .MuiTabs-indicator": { backgroundColor: "transparent" },
            "& .Mui-selected": { backgroundColor: "#d9e0eb" },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={tab.label}
              label={tab.label}
              sx={{
                flexDirection : "row",
                justifyContent: "start",
                fontWeight: "bold",
                padding: 0,
                "& .MuiSvgIcon-root" : {
                  margin: "0 5px 0 10px"
                },
                "&.Mui-selected": {
                  backgroundColor: "#d9e0eb",
                  fontWeight: "bold",
                },
                minHeight: 60
              }}
              icon={tab.icon}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        {tabs.map((tab, index) => {
          const Component = tab.component;
          return (
            <CustomTabPanel key={index} value={value} index={index}>
              <Component />
            </CustomTabPanel>
          );
        })}
      </Box>
    </Paper>
  );
};

export default MyAccount;
