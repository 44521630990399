import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../pages/Login";
import MainPage from "../pages/MainPage";
import ResponsiveNavBar from "../NavBar/index";
import AppFooter from "../AppFooter";
import { Paper, Stack } from "@mui/material";
import OrderDetails from "../pages/OrderDetails";
import RequestService from "../pages/RequestService";
import ForgetPassword from "../pages/ForgetPassword";
import CheckCode from "../pages/CheckCode";
import ResetPassword from "../pages/ResetPassword";
import ProtectedRoute from "./ProtectedRoute";
import RedirectToHomeIfAuthenticated from "./RedirectToHomeIfAuthenticated";
import BreadCrumb from "../Components/BreadCrumb";
import ServiceOrder from "../Components/ServiceOrder";
import ServicesGuide from "../pages/ServicesGuide";
import ServiceGuideDetails from "../pages/ServiceGuideDetails";
import Signup from "../pages/Signup";
import MyAccount from "../pages/MyAccount";
import MyOrders from "../pages/MyOrders";
import MyNotifications from "../pages/MyNotifications";
import MyServices from "../pages/MyServices";
import TermsAndConditions from "../pages/TermsAndConditions";
import PrivacyAndProtection from "../pages/PrivacyAndProtection";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../appInsights';

// Define the tracked components
const MainPageWithTracking = withAITracking(reactPlugin, MainPage);
const OrderDetailsWithTracking = withAITracking(reactPlugin, OrderDetails);
const RequestServiceWithTracking = withAITracking(reactPlugin, RequestService);
const ServiceOrderWithTracking = withAITracking(reactPlugin, ServiceOrder);
const MyAccountWithTracking = withAITracking(reactPlugin, MyAccount);
const MyOrdersWithTracking = withAITracking(reactPlugin, MyOrders);
const MyNotificationsWithTracking = withAITracking(reactPlugin, MyNotifications);
const MyServicesWithTracking = withAITracking(reactPlugin, MyServices);
const ServicesGuideWithTracking = withAITracking(reactPlugin, ServicesGuide);
const ServiceGuideDetailsWithTracking = withAITracking(reactPlugin, ServiceGuideDetails);
const LoginWithTracking = withAITracking(reactPlugin, Login);
const ForgetPasswordWithTracking = withAITracking(reactPlugin, ForgetPassword);
const CheckCodeWithTracking = withAITracking(reactPlugin, CheckCode);
const ResetPasswordWithTracking = withAITracking(reactPlugin, ResetPassword);
const SignupWithTracking = withAITracking(reactPlugin, Signup);
const TermsAndConditionsWithTracking = withAITracking(reactPlugin, TermsAndConditions);
const PrivacyAndProtectionWithTracking = withAITracking(reactPlugin, PrivacyAndProtection);

const AppRoutes = () => {
  return (
    <Stack sx={{ gap: { xs: 1, md: 3 } }}>
      <Router>
        <ResponsiveNavBar />
        <BreadCrumb />
        <Paper sx={{ backgroundColor: "#F9FAFC" }} elevation={2}>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <MainPageWithTracking />
                </ProtectedRoute>
              }
            />
            <Route
              path="/order-details/:id"
              element={
                <ProtectedRoute>
                  <OrderDetailsWithTracking />
                </ProtectedRoute>
              }
            />
            <Route
              path="/service-request"
              element={
                <ProtectedRoute>
                  <RequestServiceWithTracking />
                </ProtectedRoute>
              }
            />
            <Route
              path="/services"
              element={
                <ProtectedRoute>
                  <ServiceOrderWithTracking />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-account"
              element={
                <ProtectedRoute>
                  <MyAccountWithTracking />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-orders"
              element={
                <ProtectedRoute>
                  <MyOrdersWithTracking />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-notifications"
              element={
                <ProtectedRoute>
                  <MyNotificationsWithTracking />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-services"
              element={
                <ProtectedRoute>
                  <MyServicesWithTracking />
                </ProtectedRoute>
              }
            />
            <Route path="/services-guide" element={<ServicesGuideWithTracking />} />
            <Route path="/service-guide-details" element={<ServiceGuideDetailsWithTracking />} />
            <Route
              path="/login"
              element={
                <RedirectToHomeIfAuthenticated>
                  <LoginWithTracking />
                </RedirectToHomeIfAuthenticated>
              }
            />
            <Route
              path="/forget-password"
              element={
                <RedirectToHomeIfAuthenticated>
                  <ForgetPasswordWithTracking />
                </RedirectToHomeIfAuthenticated>
              }
            />
            <Route
              path="/code-check/:userId"
              element={
                <RedirectToHomeIfAuthenticated>
                  <CheckCodeWithTracking />
                </RedirectToHomeIfAuthenticated>
              }
            />
            <Route
              path="/reset-password/:userId/:code"
              element={
                <RedirectToHomeIfAuthenticated>
                  <ResetPasswordWithTracking />
                </RedirectToHomeIfAuthenticated>
              }
            />
            <Route path="sign-up" element={<SignupWithTracking />} />
            <Route path="terms-and-conditions" element={<TermsAndConditionsWithTracking />} />
            <Route path="privacy-and-protection" element={<PrivacyAndProtectionWithTracking />} />
          </Routes>
        </Paper>
        <AppFooter />
      </Router>
    </Stack>
  );
};

export default AppRoutes;
