import React, { useEffect, useContext, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  useGridApiRef,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, Stack, Divider } from "@mui/material";
import { convertTimeFormat } from "../../utils/timeFormatter";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import CustomPager from "../CustomPager";
import { CircularProgress } from "@mui/material";
import config from "../../config";

const columnHeader = (params) => {
  return (
    <Box>
      <Typography variant="h7"> {params.colDef.headerName}</Typography>
    </Box>
  );
};

const DesktopMyServices = ({ services, isLoading }) => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogData, setDialogData] = useState({
    serviceType: "",
    location: "",
    subscriberCode: "",
    beneficiaryType: "",
    address: "",
  });
  const [accruals, setAccruals] = useState([]);
  const [accrualsLoading, setAccrualsLoading] = useState(false);
  const showAccruals = config.showAccrualData;

  useEffect(() => {
    const getAccrualsData = async () => {
      try {
        if (showAccruals) {
          setAccrualsLoading(true);
          const data = await axios.get("/User/ServicesAccruals");
          setAccruals(data);
          setAccrualsLoading(false);
        }
      } catch (e) {
        setAccrualsLoading(false);
        console.log("e", e);
      }
    };
    getAccrualsData();
  }, []);

  const handleCloseDialog = () => {
    setDialogData({});
    setOpenDialog(false);
  };
  const apiRef = useGridApiRef();
  const handleViewMessageClick = ({
    serviceType,
    location,
    subscriberCode,
    beneficiaryType,
    address,
    accruals,
  }) => {
    setDialogData({
      serviceType,
      location,
      subscriberCode,
      beneficiaryType,
      address,
      accruals,
    });
    setOpenDialog(true);
  };

  const getAccruals = (subscriberId) => {
    const accrualsData = accruals.find(
      (item) => item?.subscriberId === subscriberId
    );
    return accrualsData?.subscriberId
      ? `${accrualsData?.balance} ${accrualsData?.currency}`
      : "";
  };

  const columns = [
    {
      field: "subscriberCode",
      headerName: "رمز الاشتراك",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      headerClassName: "table-header",
      align: "center",
    },
    {
      field: "serviceType",
      headerName: "نوع الخدمة",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      headerClassName: "table-header",
      align: "center",
      valueGetter: (obj) => {
        return obj.row?.serviceType.name;
      },
    },
    {
      field: "location",
      headerName: "الموقع",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      headerClassName: "table-header",
      align: "center",
    },
    {
      field: "beneficiaryType",
      headerName: "نوع المستفيد",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      headerClassName: "table-header",
      align: "center",
      valueGetter: (obj) => {
        return obj.row?.beneficiaryType?.name;
      },
    },
    showAccruals && {
      field: "accruals",
      headerName: "المستحقات",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      headerClassName: "table-header",
      align: "center",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {accrualsLoading ? (
              <CircularProgress size={20} />
            ) : (
              <Typography>{getAccruals(params.row?.subscriberId)}</Typography>
            )}
          </div>
        );
      },      
    },
    {
      field: "عرض",
      headerName: "عرض",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      headerClassName: "table-header",
      align: "center",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            onClick={() =>
              handleViewMessageClick({
                serviceType: params.row.serviceType.name,
                location: params.row.location,
                subscriberCode: params.row.subscriberCode,
                beneficiaryType: params.row?.beneficiaryType?.name,
                address: params.row.addressAr,
                accruals: getAccruals(params.row?.subscriberId),
              })
            }
          />
        );
      },
    },
  ].filter(Boolean);

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        "& .table-header": {
          backgroundColor: "#DFE7F2",
          color: "#BF3C4E",
          border: "1px solid white",
        },
        "& .MuiDataGrid-columnHeaders": {
          overflowX: { xs: "scroll", md: "hidden" },
        },
        "& .MuiDataGrid-footerContainer": {
          direction: "ltr",
        },
        padding: 2,
      }}
    >
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        sx={{
          "& .MuiDialog-paper": {
            border: "2px solid #BF3C4E", // Set the border color here
            padding: "20px",
            borderRadius: "10px",
          },
        }}
      >
        <Stack gap={3} sx={{ marginBottom: 2, padding: { xs: 3, md: 4 } }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                رمز الاشتراك :
              </Typography>
              <Typography>{dialogData.subscriberCode}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>نوع الخدمة :</Typography>
              <Typography>{dialogData.serviceType}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>الموقع :</Typography>
              <Typography>{dialogData.location}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                نوع المستفيد :
              </Typography>
              <Typography>{dialogData.beneficiaryType}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>العنوان :</Typography>
            <Typography>{dialogData.address}</Typography>
          </Box>
          {showAccruals ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>المستحقات :</Typography>
              <Typography>{dialogData.accruals}</Typography>
            </Box>
          ) : (
            <></>
          )}
        </Stack>
        <Button
          type="sumbit"
          sx={{ width: "30%", alignSelf: "center" }}
          variant="contained"
          onClick={handleCloseDialog}
        >
          <Typography>اغلاق</Typography>
        </Button>
      </Dialog>
      <DataGrid
        rows={services.map((item, index) => ({ ...item, id: index }))}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        apiRef={apiRef}
        slots={{
          pagination: CustomPager,
        }}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default DesktopMyServices;
