import {Controller, useForm} from "react-hook-form";
import {Box, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import axios, {isAxiosError} from "axios";
import {useNavigate} from "react-router-dom";
import {LoadingButton} from '@mui/lab';

const ForgetPasswordForm = () => {
    const {
        handleSubmit,
        control,
        setError,
        formState: {errors},
    } = useForm({
        mode: "onChange",
    });
    const [isLoading, setIsLoading] = useState(false);
    const nav = useNavigate();
    const onSubmit = (data) => {
        setIsLoading(true);

        axios.post(`/Auth/ForgotPassword`, {documentNumber: data.idNumber}).then(res => {
            const userId = res.userId;
            setIsLoading(false);
            nav(`/code-check/${userId}`);
        }).catch(e => {
            setIsLoading(false);
            setError("idNumber", {message: e.response.data.message})

        });
    }
    return (
        <Stack sx={{p: 3, gap: 10, width: "90%"}}>
            <Typography
                sx={{fontWeight: "bold", alignSelf: "center"}}
                color="primary.main"
                variant="h5"
            >
                نسيت كلمة المرور
            </Typography>
            <Stack>
                <Typography sx={{fontWeight: "bold"}}>رقم لهوية</Typography>
                <Controller
                    name="idNumber"
                    control={control}
                    rules={{
                        required: "يجب ادخال رقم الهوية",
                        minLength: {value: 9, message: "رقم الهوية مكون من 9 ارقام"},
                        maxLength: {value: 9, message: "رقم الهوية مكون من 9 ارقام"},
                        pattern: {
                            value: /^[0-9]{9}$/,
                            message: "رقم الهوية مكون من 9 ارقام",
                        },
                    }}
                    render={({field}) => (
                        <TextField
                            {...field}
                            variant="standard"
                            inputProps={{
                                style: {
                                    textAlign: "center",
                                    height: 37,
                                    borderBottomRightRadius: 0,
                                    borderTopRightRadius: 0,
                                },
                            }}
                            InputProps={{
                                disableUnderline: true,
                                placeholder: "ادخل رقم الهوية",
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: 48,
                                                height: 48,
                                                backgroundColor: "#EAF0F7",
                                                borderBottomRightRadius: 10,
                                                borderTopRightRadius: 10,
                                            }}
                                        >
                                            <PersonIcon color="primary"/>
                                        </Box>
                                    </InputAdornment>
                                ),
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    textAlign: "start",
                                },
                            }}
                            error={!!errors.idNumber}
                            helperText={errors.idNumber ? errors.idNumber.message : ""}
                        />
                    )}
                />
            </Stack>

            <LoadingButton
                type="sumbit"
                loading={isLoading}
                sx={{width: "80%", alignSelf: "center"}}
                variant="contained"
                onClick={handleSubmit(onSubmit)}
            >
                <Typography>استعادة كلمة المرور</Typography>
            </LoadingButton>
        </Stack>
    );
}
export default ForgetPasswordForm;