import { Grid, Paper, Stack } from "@mui/material";
import React from "react";
import ResetPasswordForm from "../Components/ResetPasswordForm";
import { isMobile } from "react-device-detect";
import CreateUser from "../Components/CreateUser";
import config from "../config";

const ResetPassword = () => {
  const showRegForm = config.showRegistrationForm;
  return (
    <Paper elevation={5}>
      <Grid container>
        <Grid
          sx={
            !isMobile
              ? {
                  boxShadow: "-10px 0px 10px 0px rgba(0, 0, 0, 0,8)",
                  ...(showRegForm ? {} : { margin: "0 auto" }),
                }
              : {}
          }
          item
          display="flex"
          justifyContent="center"
          xs={12}
          md={showRegForm ? 6 : 5}
        >
          <ResetPasswordForm />
        </Grid>

        {showRegForm && (
          <Grid
            sx={isMobile ? { py: 4 } : { backgroundColor: "#F4F8FC" }}
            xs={12}
            item
            md={6}
          >
            <CreateUser />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
export default ResetPassword;
