import {Stack, Button, Typography, Box} from "@mui/material";
import React from "react";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {isMobile} from "react-device-detect";
import { useNavigate } from "react-router-dom";

const CreateUser = () => {
    const isNotMobile = !isMobile ? {width: "90%", height: "80px", my: 2} : {mx: 2};
    const navigate = useNavigate()
    const onCreateNewUserClick = () => {
        navigate("/sign-up")
    }
    return (
        <Stack sx={{alignItems: "center", height: "100%", justifyContent: "space-between", mt: 3,borderTopLeftRadius: "27px" ,  borderBottomLeftRadius: "27px"
        }}>
            <Typography sx={{fontWeight: "bold"}} variant="h5" color="primary.main">تسجيل دخول بهويتك الفلسطينية</Typography>
            <Button sx={{...isNotMobile, backgroundColor: "#fff"}} size="large" variant="outlined" onClick={onCreateNewUserClick}>
                <Stack sx={{width: "100%"}} alignItems="center" direction="row" gap={3}>
                    <Box sx={{display: "flex", background: "#e4e9ef", p: 2, borderRadius: "7px"}}>
                        <PersonAddIcon fontSize="large"/>
                    </Box>
                    <Typography sx={{fontWeight: "bold"}}>تسجيل مستخدم جديد</Typography>
                    <Box sx={{width: 50}}></Box>
                </Stack>
            </Button>
            <Box></Box>
            <Box></Box>
        </Stack>
    )
}
export default CreateUser;