import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Stack,
  Typography,
  TextField,
  Divider,
  MenuItem,
  IconButton,
  Dialog,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useForm, Controller } from "react-hook-form";
import { UserDetailsContext } from "../../Context/userDetailsContext";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PreviewAttachmentDialog from "../PreviewAttachmentDialog/index";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LoadingButton } from "@mui/lab";
import { isMobile } from "react-device-detect";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { fileToBase64 } from "../../utils/fileToBase64";
import MessageModal from "../MessageModal";
import CancelIcon from "@mui/icons-material/Cancel";

import GeneralComponent from "./GeneralComponent";
import CertificateComponent from "./CertificateComponent";
import ElectricityComponent from "./ElectricityComponent";
import WaterComponent from "./WaterComponent";
import CraftmentForm from "./CraftmentForm";
import ClearanceCertificateForm from "./ClearanceCertificateForm";
import ChooseLocationFromMapForm from "./ChooseLocationFromMapForm";
import ServiceTypeForm from "./ServiceTypeForm";
import { serviceTypeEnum } from "./ServiceTypeEnum";
import { ConfigLinksDetailsContext } from "../../Context/cofingLinksDetailsContext";
import { validFileTypes } from "../../constants";
import { filePathToFile } from "../../utils/pathToFileObject";

const RequestServiceForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { userDetails } = useContext(UserDetailsContext);
  const { configLinks } = useContext(ConfigLinksDetailsContext);
  const [categories, setCategories] = useState([]);
  const [externalEntities, setExternalEntities] = useState([]);
  const [propertyUsages, setPropertyUsages] = useState([]);
  const [currentTypes, setCurrentTypes] = useState([]);
  const [craftsAndIndustries, setCraftsAndIndustries] = useState([]);
  const [beneficiaryTypes, setBeneficiaryTypes] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [quarters, setQuarters] = useState([]);
  const [parcels, setParcels] = useState([]);
  const [showFromMap, setShowFromMap] = useState(false);
  const [services, setServices] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [showFile, setShowFile] = useState(false);
  const [showAppliedBy, setShowAppliedBy] = useState(false);
  const [fileToPreviewData, setFileToPreviewData] = useState();
  const [openSubmissionModal, setOpenSubmissionModal] = useState(false);
  const [openFailiurModal, setOpenFailureModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const isGeneralForm =
    state.fragment === "General" || state.fragment === "GeneralCategories";
  const withCategories = state.fragment === "GeneralCategories";
  const isWithCertificateForm =
    state.fragment === "Certificate" || state.isWithCertificate;
  const isElectricityForm = state.fragment === "Electricity";
  const isWaterForm = state.fragment === "Water";
  const isCraftmentForm = state.fragment === "NewCrafts";
  const isFromMapForm =
    state.serviceSelectType === serviceTypeEnum.showFromMapAndServiceTypeForm ||
    state.serviceSelectType === serviceTypeEnum.showFromMapForm;
  const isServiceTypeForm =
    state.serviceSelectType === serviceTypeEnum.showFromMapAndServiceTypeForm ||
    state.serviceSelectType === serviceTypeEnum.showServiceTypeForm;
  const isClearanceCertificateForm = state.fragment === "ClearanceCertificate";
  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoadingData(true);

        const fetchCategories = () =>
          axios.get("/lookup/category").then(setCategories);
        const fetchExternalEntities = () =>
          axios.get("/lookup/ExternalEntities").then(setExternalEntities);
        const fetchPropertyUsages = () =>
          axios.get("/lookup/UseProperties").then(setPropertyUsages);
        const fetchElectricityVoltages = () =>
          axios.get("/lookup/ElectricityVoltages").then(setCurrentTypes);
        const fetchCraftsAndIndustries = () =>
          axios.get("/lookup/CraftsAndIndustries").then(setCraftsAndIndustries);
        const fetchServices = () =>
          axios.get(`/User/${userDetails.userId}/Services`).then(setServices);
        const fetchMapData = () =>
          axios
            .all([
              axios.get("/lookup/BeneficiaryTypes"),
              axios.get("/lookup/Blocks"),
              axios.get("/lookup/Quarter"),
              axios.get("/lookup/Parcels"),
            ])
            .then(
              axios.spread((beneficiaryTypes, blocks, quarters, parcels) => {
                setBeneficiaryTypes(beneficiaryTypes);
                setBlocks(blocks);
                setQuarters(quarters);
                setParcels(parcels);
              })
            );

        const promises = [];
        if (withCategories) promises.push(fetchCategories());
        if (isWithCertificateForm || isClearanceCertificateForm)
          promises.push(fetchExternalEntities());
        if (isElectricityForm) {
          promises.push(fetchPropertyUsages());
          promises.push(fetchElectricityVoltages());
        }
        if (isWaterForm) promises.push(fetchPropertyUsages());
        if (isCraftmentForm) promises.push(fetchCraftsAndIndustries());
        if (isFromMapForm) promises.push(fetchMapData());
        if (isServiceTypeForm || isClearanceCertificateForm)
          promises.push(fetchServices());

        await axios.all(promises);

        setIsLoadingData(false);
      } catch (error) {
        console.log("Error loading data", error);
        setIsLoadingData(false);
      }
    };
    const getAttachments = async () => {
      try {
        setAttachmentLoading(true);
        const allUserAttachments = await axios.get(`/user/attachments`);
        setAttachmentLoading(false);
        state.requiredAttachments.forEach(async (neededAttachment, index) => {
          const alreadyExistingAttachment = allUserAttachments.find(
            (item) =>
              item.attachmentType.id === neededAttachment.attachmentTypeId
          );
          if (alreadyExistingAttachment) {
            const fileObject = await filePathToFile(
              alreadyExistingAttachment.attachmentPath,
              alreadyExistingAttachment.attachmentName
            );
            setValue(`attachment${index}`, fileObject);
          }
        });
      } catch (e) {
        console.log("e", e);
      }
    };
    getData();
    getAttachments();
  }, []);

  useEffect(() => {
    if (state.serviceSelectType === serviceTypeEnum.showFromMapForm) {
      setShowFromMap(true);
    }
  }, [state.serviceSelectType]);

  const buildDetails = ({ data }) => {
    let stringifiedData;
    switch (true) {
      case state.fragment === "General":
        stringifiedData = [{ Notes: data?.details }];
        break;
      case state.fragment === "GeneralCategories":
        stringifiedData = [
          { Notes: data?.details, GeneralCategories: data?.category },
        ];
        break;
      case state.fragment === "Certificate":
        stringifiedData = {
          ExternalEntities: externalEntities.find(
            (item) => item.id == data?.externalEntities
          )?.name,
        };
        break;
      case state.fragment === "Electricity":
        stringifiedData = [
          {
            UseProperty: data?.propertyUsage,
            PropertyDescription: data?.propertyDescription,
            ElectricityVoltage: data?.currentType,
          },
        ];
        break;
      case state.fragment === "Water":
        stringifiedData = [
          {
            UseProperty: data?.propertyUsage,
            PropertyDescription: data?.propertyDescription,
          },
        ];
        break;
      case state.fragment === "NewCrafts":
        const selectedCraft = getValues("craftsAndIndustries");
        stringifiedData = [
          {
            CraftsType: selectedCraft.id,
            CraftPrice: selectedCraft.price,
            RenterName: data.renterName,
            RenterDocument: data?.renterDocument,
            TradeName: data?.tradeName,
            BusinessLicenseNo: data?.businessLicenseNumber,
            CraftsNotes: data?.craftNotes,
          },
        ];
        break;
      case state.fragment === "ClearanceCertificate":
        const selectedService = services.map(
          (service) => service.subscriberNumber
        );
        stringifiedData = {
          servicesList: JSON.stringify(selectedService),
          ExternalEntities: externalEntities.find(
            (item) => item.id == data?.externalEntities
          )?.name,
        };
        break;
    }
    if (state.isWithCertificate) {
      return JSON.stringify([
        stringifiedData,
        {
          ExternalEntities: externalEntities.find(
            (item) => item.id == data?.externalEntities
          )?.name,
        },
      ]);
    }
    return JSON.stringify(stringifiedData);
  };

  const buildMapData = ({ data }) => {
    var jsonArr = [];
    const dataObject = {
      BlockCode: data?.block?.code,
      BlockNum: data?.block?.code,
      BlockNameA: data?.block?.name,
      QuarterCode: data?.quarter?.code,
      QuarterNameA: data?.quarter?.name,
      ParcelNumber: data?.parcel?.code,
    };
    jsonArr.push(dataObject);

    return JSON.stringify(jsonArr);
  };

  const onViewDocumentClick = (file) => {
    setFileToPreviewData({ fileObject: file });
    setShowFile(true);
  };

  const handleCloseFilePreview = () => {
    setShowFile(false);
    setFileToPreviewData();
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const combinedServiceData = buildDetails({
        details: data?.details,
        selectedCategory: data?.category,
        data,
      });
      const formData = new FormData();
      state.requiredAttachments.forEach((item, index) => {
        if (
          data[`attachment${index}`]?.name ||
          data[`attachment${index}`]?.type ||
          data[`attachment${index}`]?.file
        ) {
          formData.append(
            `attachments[${index}].File`,
            data[`attachment${index}`]
          );
          formData.append(
            `attachments[${index}].AttachmentType`,
            item?.attachmentTypeId
          );
        }
      });

      const dataRequestObj = {
        applicantId: userDetails.userId,
        subscriberNumber: data?.selectedService
          ? `${data?.selectedService}`
          : "",
        accountFor: userDetails.userId,
        isFromMap: showFromMap,
        mapData: showFromMap ? buildMapData({ data }) : "",
        beneficiaryType: data?.beneficiaryType
          ? `${data?.beneficiaryType}`
          : "",
        publicService: state.serviceId,
        serviceGoal: data.serviceGoal,
        serviceReviewNote: "",
        appliedBy: data.appliedBy,
        appliedByDocumentNumber: data.appliedByDocumentNumber,
        appliedByRelation: data.appliedByRelation,
        serviceData: combinedServiceData || null,
      };
      const applicationResponse = await axios.post(
        "/application",
        dataRequestObj
      );
      await axios.post(
        `/application/${applicationResponse.subscriberServiceId}/attachments`,
        formData
      );
      setIsLoading(false);
      setOpenSubmissionModal(true);
    } catch (e) {
      console.log("e", e);
      setIsLoading(false);
      setOpenFailureModal(true);
    }
  };

  const handleFileUploadClick = (index) => {
    document.getElementById(`attachment${index}`).click();
  };

  const onImageUpload = async (field, file, index) => {
    const maxSizeInMB = configLinks.fileSizeLimitMb;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Convert MB to bytes
    try {
      if (file && validFileTypes.includes(file.type)) {
        if (file.size <= maxSizeInBytes) {
          field.onChange(file);
          // Clear any previous error message
          setError(`attachment${index}`, null);
        } else {
          // Set an error message if the file size exceeds 2 MB
          setError(`attachment${index}`, {
            type: "validate",
            message: `حجم الملف لا يمكن ان يتجاوز ${configLinks.fileSizeLimitMb} MB`,
          });
        }
      } else {
        // Set an error message if the file type is invalid
        setError(`attachment${index}`, {
          type: "validate",
          message: "الرجاء تحميل ملف صالح (JPEG, JPG, PNG, PDF)",
        });
      }
    } catch (e) {
      console.log("error from input", e);
    }
  };

  const onSuccessButtonClick = () => {
    navigate("/");
  };

  const handleShowServiceTitleForms = (ServiceSelectType) => {
    switch (ServiceSelectType) {
      case serviceTypeEnum.showFromMapAndServiceTypeForm:
        return (
          <>
            <ChooseLocationFromMapForm
              control={control}
              errors={errors}
              setValue={setValue}
              isServiceRequired={state.isServiceRequired}
              beneficiaryTypes={beneficiaryTypes}
              blocks={blocks}
              parcels={parcels}
              quarters={quarters}
              setShowFromMap={setShowFromMap}
              showFromMap={showFromMap}
              bothFormsPresented={true}
            />
            <ServiceTypeForm
              isServiceRequired={state.isServiceRequired}
              control={control}
              errors={errors}
              setValue={setValue}
              services={services}
              showFromMap={showFromMap}
            />
          </>
        );

      case serviceTypeEnum.showFromMapForm:
        return (
          <ChooseLocationFromMapForm
            control={control}
            errors={errors}
            setValue={setValue}
            isServiceRequired={state.isServiceRequired}
            beneficiaryTypes={beneficiaryTypes}
            blocks={blocks}
            parcels={parcels}
            quarters={quarters}
          />
        );
      case serviceTypeEnum.showServiceTypeForm:
        return (
          <ServiceTypeForm
            isServiceRequired={state.isServiceRequired}
            control={control}
            errors={errors}
            setValue={setValue}
            services={services}
          />
        );
      case serviceTypeEnum.showNone:
        return <></>;
      default:
        return <></>;
    }
  };

  if (isLoadingData) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2}>
          <Divider textAlign="left">
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography sx={{ fontWeight: "bold" }}>قدم بواسطة</Typography>
              <IconButton onClick={() => setShowAppliedBy((prev) => !prev)}>
                <ExpandCircleDownIcon
                  color="primary"
                  sx={{
                    transform: showAppliedBy
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                />
              </IconButton>
            </Stack>
          </Divider>
          {showAppliedBy && (
            <>
              <Grid
                sx={{ background: "#fff", p: 2, width: "100%" }}
                spacing={2}
                container
              >
                <Grid item md={4} xs={12}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    قدم بواسطة
                  </Typography>
                  <Controller
                    name="appliedBy"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        InputProps={{
                          placeholder: "",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    رقم الوثيقة
                  </Typography>
                  <Controller
                    name="appliedByDocumentNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        inputProps={{
                          style: {},
                        }}
                        InputProps={{
                          placeholder: "",
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <Typography sx={{ fontWeight: "bold" }}>الصفة</Typography>
                  <Controller
                    name="appliedByRelation"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        InputProps={{
                          placeholder: "",
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Divider />
            </>
          )}

          <Box
            sx={{
              background: "#fff",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 1,
              width: isMobile ? "unset" : 300,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              عنوان الطلب
            </Typography>
          </Box>

          <Grid container>
            <Grid sx={{ background: "#fff", p: 2 }} item xs={12} md={12}>
              <Typography sx={{ fontWeight: "bold" }}>
                الغاية من تقديم الطلب *
              </Typography>
              <Controller
                name="serviceGoal"
                control={control}
                defaultValue=""
                rules={{
                  required: "يجب ادخال الغاية من تقديم الطلب",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    FormHelperTextProps={{
                      sx: {
                        textAlign: "start",
                      },
                    }}
                    error={!!errors.serviceGoal}
                    helperText={
                      errors.serviceGoal ? errors.serviceGoal.message : ""
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          {isGeneralForm && (
            <GeneralComponent
              control={control}
              categories={categories}
              errors={errors}
              withCategories={withCategories}
            />
          )}
          {isWithCertificateForm && (
            <CertificateComponent
              externalEntities={externalEntities}
              control={control}
              errors={errors}
            />
          )}
          {isElectricityForm && (
            <ElectricityComponent
              currentTypes={currentTypes}
              propertyUsage={propertyUsages}
              control={control}
              errors={errors}
            />
          )}
          {isWaterForm && (
            <WaterComponent
              propertyUsage={propertyUsages}
              control={control}
              errors={errors}
            />
          )}
          {isCraftmentForm && (
            <CraftmentForm
              control={control}
              errors={errors}
              craftsAndIndustries={craftsAndIndustries}
            />
          )}
          {isClearanceCertificateForm && (
            <ClearanceCertificateForm
              control={control}
              errors={errors}
              setValue={setValue}
              services={services}
              externalEntities={externalEntities}
            />
          )}
          {handleShowServiceTitleForms(state.serviceSelectType)}
          {state.requiredAttachments.length ? (
            attachmentLoading ? (
              <Box sx={{ textAlign: "center" }}>
                <CircularProgress size={80} />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    background: "#fff",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 1,
                    width: isMobile ? "unset" : 300,
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    نوع المرفق
                  </Typography>
                </Box>
                <Grid sx={{ background: "#fff", p: 2 }} container>
                  {state.requiredAttachments.map((item, index) => (
                    <React.Fragment key={item.attachmentTypeId || index}>
                      {index === 0 && (
                        <>
                          <Grid sx={{ mb: 2 }} xs={6} md={3} item>
                            <Typography sx={{ fontWeight: "bold" }}>
                              نوع المرفق
                            </Typography>
                          </Grid>
                          <Grid xs={6} md={9} item>
                            <Typography sx={{ fontWeight: "bold" }}>
                              تحميل الملف
                            </Typography>
                          </Grid>
                        </>
                      )}
                      <Grid
                        item
                        xs={6}
                        md={3}
                        key={item.attachmentTypeId || index}
                      >
                        <Typography>
                          {`${item.attachmentType} ${
                            item.isMandatory ? " *" : ""
                          }`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={9}>
                        <Controller
                          name={`attachment${index}`}
                          control={control}
                          rules={{
                            required: item.isMandatory
                              ? "هذا الحقل مطلوب"
                              : false,
                          }}
                          render={({ field }) => {
                            return (
                              <>
                                <input
                                  type="file"
                                  id={`attachment${index}`}
                                  style={{ display: "none" }}
                                  onChange={(e) =>
                                    onImageUpload(
                                      field,
                                      e.target.files[0],
                                      index
                                    )
                                  }
                                />
                                <IconButton
                                  onClick={() => handleFileUploadClick(index)}
                                >
                                  <AttachFileIcon />
                                </IconButton>
                                {field.value && (
                                  <>
                                    <IconButton
                                      onClick={() =>
                                        onViewDocumentClick(field.value)
                                      }
                                    >
                                      <VisibilityIcon color={"primary"} />
                                    </IconButton>
                                  </>
                                )}
                                {errors[`attachment${index}`] && (
                                  <Typography color="error">
                                    {errors[`attachment${index}`].message}
                                  </Typography>
                                )}
                              </>
                            );
                          }}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </>
            )
          ) : null}

          <LoadingButton
            type="submit"
            sx={{ width: isMobile ? "80%" : "30%", alignSelf: "center" }}
            variant="contained"
            loading={isLoading}
          >
            <Typography>تقديم الطلب</Typography>
          </LoadingButton>
          {Object.keys(errors).length ? (
            <Typography color="error">
              يرجى التاكد من تعبئة جميع الحقول المطلوبة
            </Typography>
          ) : null}
        </Stack>

        {fileToPreviewData && (
          <PreviewAttachmentDialog
            open={showFile}
            attachmentDetails={fileToPreviewData}
            handleClose={handleCloseFilePreview}
          />
        )}
      </form>

      <MessageModal
        openModal={openFailiurModal}
        message={
          "لقد حدث خطا اثناء عملية طلب الخدمة, يرجى المحاولة مرة اخرى فيما بعد"
        }
        buttonText={"اغلاق"}
        interfaceIcon={
          <CancelIcon color={"primary"} sx={{ height: 80, width: 80 }} />
        }
        onCloseModal={() => setOpenFailureModal(false)}
      />

      <Dialog
        open={openSubmissionModal}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            border: "1px solid #BC1F41",
            borderRadius: 3,
            padding: 3,
          },
        }}
      >
        <Stack justifyContent={"center"} alignItems={"center"}>
          <CheckCircleIcon color={"primary"} sx={{ height: 80, width: 80 }} />
          <Typography sx={{ fontWeight: "bold" }} variant="h6">
            تم تقديم طلبكم بنجاح
          </Typography>
          <Typography sx={{ fontWeight: "bold" }} variant="h6">
            سوف يتم معالجة طلبكم في اقرب وقت ممكن
          </Typography>
          <Button
            type="submit"
            sx={{ width: "30%", alignSelf: "center", marginTop: 4 }}
            variant="contained"
            onClick={onSuccessButtonClick}
          >
            <Typography>العودة الي لوحة البيانات</Typography>
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};

export default RequestServiceForm;
