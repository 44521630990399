import React, { useEffect, useContext, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, Stack, Divider } from "@mui/material";
import { convertTimeFormat } from "../../utils/timeFormatter";
import axios from "axios";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import Dialog from "@mui/material/Dialog";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DOMPurify from 'dompurify';
import {CircularProgress} from "@mui/material";
import CustomPager from "../CustomPager";

const columnHeader = (params) => {
  return (
    <Box>
      <Typography variant="h7"> {params.colDef.headerName}</Typography>
    </Box>
  );
};

const DesktopMyNotifications = ({notifications, isLoading}) => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogData, setDialogData] = useState({
    sentBy: "",
    time: "",
    message: "",
  });


  const handleCloseDialog = () => {
    setDialogData({});
    setOpenDialog(false);
  };

  const handleViewMessageClick = ({ message, sentBy, time }) => {
    setDialogData({ sentBy, message, time });
    setOpenDialog(true);
  };

  const handleOrderNumberClick = (id) => {
    navigate(`/order-details/${id}`);
  };
  const apiRef = useGridApiRef();
  const columns = [
    {
      field: "timestamp",
      headerName: "وقت الارسال",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      headerClassName: "table-header",
      align: "center",
      valueGetter: (obj) => {
        return convertTimeFormat(obj.value);
      },
    },
    {
      field: "type",
      headerName: "نوع الاشعار",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      headerClassName: "table-header",
      align: "center",
      valueGetter: (obj) => {
        return (obj.row.type.name);
      },
    },
    {
      field: "sentToName",
      headerName: "ارسل الى",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      headerClassName: "table-header",
      align: "center",
    },
    {
      field: "sentTo",
      headerName: "معلومات الارسال",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      headerClassName: "table-header",
      align: "center",
    },
    {
      field: "subscriberServiceFullNumber",
      headerName: "رقم الطلب",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      headerClassName: "table-header",
      align: "center",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {params.value && (
              <>
                <Typography>{params.value}</Typography>
                <GridActionsCellItem
                  icon={<OpenInNewIcon sx={{ color: "#BF3C4E" }} />}
                  label="View"
                  onClick={() => handleOrderNumberClick(params.row.subscriberServiceId)}
                />
              </>
            )}
          </Box>
        );
      },
    },
    {
      field: "message",
      headerName: "الرسالة",
      editable: false,
      flex: 1,
      renderHeader: (params) => columnHeader(params),
      headerAlign: "center",
      align: "center",
      headerClassName: "table-header",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <GridActionsCellItem
              icon={<VisibilityIcon  />}
              label="View"
              onClick={() =>
                handleViewMessageClick({
                  message: params.value,
                  sentBy: params.row.sentByUser,
                  time: convertTimeFormat(params.row.timestamp),
                })
              }
            />
          </Box>
        );
      },
    },
  ];

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
         padding: 2 ,
        "& .table-header": {
          backgroundColor: "#DFE7F2",
          color: "#BF3C4E",
          border: "1px solid white",
        },
        "& .MuiDataGrid-columnHeaders": {
          overflowX: { xs: "scroll", md: "hidden" },
        },
        "& .MuiDataGrid-footerContainer": {
          direction: "ltr",
        },
      }}
    >
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        sx={{
          "& .MuiDialog-paper": {
            border: "2px solid #BF3C4E", // Set the border color here
            padding: "20px",
            borderRadius: "10px",
          },
        }}
      >
        <Stack gap={3} sx={{ marginBottom: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>ارسل بواسطة :</Typography>
            <Typography>{dialogData.sentBy}</Typography>
          </Box>
          <Divider sx={{ width: "100%" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>وقت الارسال :</Typography>
            <Typography>{dialogData.time}</Typography>
          </Box>
          <Divider sx={{ width: "100%" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 1,
              alignItems:{xs: "start", md: "center"} 
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>نص&nbsp;الرسالة:</Typography>
            <Typography dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dialogData.message) }}></Typography>
          </Box>
        </Stack>
        <Button
          type="sumbit"
          sx={{ width: "30%", alignSelf: "center" }}
          variant="contained"
          onClick={handleCloseDialog}
        >
          <Typography>اغلاق</Typography>
        </Button>
      </Dialog>
      <DataGrid
        rows={notifications.map((item, index) => ({ ...item, id: index }))}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        apiRef={apiRef}
        slots={{
          pagination: CustomPager,
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default DesktopMyNotifications;
