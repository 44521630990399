import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Box,
  MenuItem,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { isMobile } from "react-device-detect";

const ChooseLocationFromMapForm = ({
  isServiceRequired,
  control,
  errors,
  setValue,
  beneficiaryTypes,
  blocks,
  quarters,
  parcels,
  setShowFromMap,
  showFromMap,
  bothFormsPresented,
}) => {
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [selectedQuarter, setSelectedQuarter] = useState(null);
  const showFromMapForm = bothFormsPresented ? showFromMap : true;
  const isFormRequired = bothFormsPresented
    ? showFromMap && isServiceRequired
    : isServiceRequired;

  useEffect(() => {
    if (!selectedBlock) {
      setValue("quarter", null);
      setValue("parcel", null);
      setSelectedQuarter(null);
    }
  }, [selectedBlock, setValue]);

  useEffect(() => {
    if (!selectedQuarter) {
      setValue("parcel", null);
    }
  }, [selectedQuarter, setValue]);

  return (
    <>
      <Box
        sx={{
          background: "#fff",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
          width: isMobile ? "unset" : 300,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          اختر الموقع
        </Typography>
        {bothFormsPresented && (
          <>
            <Checkbox
              checked={showFromMap}
              onChange={() => {
                setShowFromMap((prev) => !prev);
              }}
              sx={{ mr: 1 }}
              title="اختر الموقع"
            />
            <Typography>اختر الموقع</Typography>
          </>
        )}
      </Box>
      {showFromMapForm && (
        <>
          <Grid
            sx={{ background: "#fff", p: 2, mt: 1, width: "100%" }}
            spacing={4}
            container
          >
            <Grid item xs={12} md={3}>
              <Typography sx={{ fontWeight: "bold" }}>نوع المستفيد</Typography>
              <Controller
                name="beneficiaryType"
                control={control}
                defaultValue="" // Ensure a default value is provided
                rules={{
                  required: isFormRequired && "هذا الحقل مطلوب", // Make it required based on condition
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    variant="outlined"
                    value={field.value || ""} // Ensure value is not undefined
                    FormHelperTextProps={{
                      sx: {
                        textAlign: "start",
                      },
                    }}
                    error={!!errors.beneficiaryType}
                    helperText={
                      errors.beneficiaryType
                        ? errors.beneficiaryType.message
                        : ""
                    }
                  >
                    {beneficiaryTypes.map((option) => (
                      <MenuItem key={option.name} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={9}></Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{ fontWeight: "bold" }}>الحوض</Typography>
              <Controller
                name="block"
                control={control}
                defaultValue=""
                rules={{
                  required: isFormRequired && "هذا الحقل مطلوب",
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={blocks}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      field.onChange(value);
                      setSelectedBlock(value);
                      setSelectedQuarter(null);
                    }}
                    value={field.value || null} // Ensure value is not undefined
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          placeholder: "",
                        }}
                        FormHelperTextProps={{
                          sx: {
                            textAlign: "start",
                          },
                        }}
                        error={!!errors.block}
                        helperText={errors.block ? errors.block.message : ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{ fontWeight: "bold" }}>الحي</Typography>
              <Controller
                name="quarter"
                control={control}
                rules={{
                  required: isFormRequired && "هذا الحقل مطلوب",
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={!selectedBlock}
                    options={quarters.filter(
                      (quarter) => quarter.parentId === selectedBlock?.id
                    )}
                    getOptionLabel={(option) =>
                      `${option.code}  ${option.name}`
                    }
                    onChange={(event, value) => {
                      field.onChange(value);
                      setSelectedQuarter(value);
                    }}
                    value={field.value || null} // Ensure value is not undefined
                    key={selectedBlock ? selectedBlock.id : ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={!selectedBlock}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          placeholder: "",
                        }}
                        FormHelperTextProps={{
                          sx: {
                            textAlign: "start",
                          },
                        }}
                        error={!!errors.quarter}
                        helperText={
                          errors.quarter ? errors.quarter.message : ""
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{ fontWeight: "bold" }}>القطعة</Typography>
              <Controller
                name="parcel"
                control={control}
                rules={{
                  required: isFormRequired && "هذا الحقل مطلوب",
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={parcels.filter(
                      (parcel) => parcel.parentId === selectedQuarter?.id
                    )}
                    getOptionLabel={(option) => option.code}
                    onChange={(event, value) => {
                      field.onChange(value);
                    }}
                    value={field.value || null} // Ensure value is not undefined
                    key={selectedQuarter ? selectedQuarter.id : ""}
                    disabled={!selectedQuarter}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={!selectedQuarter}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          placeholder: "",
                        }}
                        FormHelperTextProps={{
                          sx: {
                            textAlign: "start",
                          },
                        }}
                        error={!!errors.parcel}
                        helperText={errors.parcel ? errors.parcel.message : ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ChooseLocationFromMapForm;
