import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const ServiceTypeTable = ({ data, selectedItem, onSelect }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const includesSearchQuery = (value) => {
    return String(value).includes(searchQuery);
  };

  const filteredData = data.filter(
    (item) =>
      includesSearchQuery(item.subscriberCode) ||
      includesSearchQuery(item.taxType) ||
      includesSearchQuery(item.parcelNumber) ||
      includesSearchQuery(item.location) ||
      (item.beneficiaryType && includesSearchQuery(item.beneficiaryType.name))
  );

  const columns = [
    { field: "subscriberCode", headerName: "رمز الاشتراك", flex: 1 },
    { field: "taxType", headerName: "نوع الضريبة", flex: 1 },
    { field: "parcelNumber", headerName: "كود الضريبة", flex: 1 },
    { field: "location", headerName: "الموقع", flex: 1 },
    { field: "beneficiaryType", headerName: "نوع المستفيد", flex: 1 },
  ];

  return (
    <TableContainer component={Paper}>
      <Box p={2} sx={{ direction: "rtl" }}>
        <TextField
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ mb: 2 }}
          placeholder="بحث"
        />
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.field}
                  align="center"
                  style={{ textAlign: "center" }}
                >
                  {column.headerName}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((item) => (
              <TableRow
                key={item.subscriberNumber}
                selected={item.subscriberNumber === selectedItem}
                onClick={() => onSelect(item.subscriberNumber)}
                hover
                style={{ cursor: "pointer" }}
              >
                <TableCell align="center">{item.subscriberCode}</TableCell>
                <TableCell align="center">{item?.serviceType?.name}</TableCell>
                <TableCell align="center">{item.taxType}</TableCell>
                <TableCell align="center">{item.location}</TableCell>
                <TableCell align="center">
                  {item.beneficiaryType?.name}
                </TableCell>
                <TableCell align="center">
                  {item.subscriberNumber === selectedItem && (
                    <CheckIcon color="primary" />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  );
};

export default ServiceTypeTable;
