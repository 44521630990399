import React, { useState } from "react";
import { Grid, Typography, TextField, Box, Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";
import { isMobile } from "react-device-detect";

const CraftmentForm = ({ craftsAndIndustries, control, errors }) => {
  const [selectedCraft, setSelectedCraft] = useState(null);

  return (
    <>
      <Box
        sx={{
          background: "#fff",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
          width: isMobile ? "unset" : 300,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          الحرف و الصناعات
        </Typography>
      </Box>
      <Grid
        sx={{ background: "#fff", p: 2, mt: 1, width: "100%" }}
        spacing={4}
        container
      >
        <Grid item xs={12} md={3}>
          <Typography sx={{ fontWeight: "bold" }}>الحرف و الصناعات</Typography>

          <Controller
            name="craftsAndIndustries"
            control={control}
            defaultValue={null} // Ensure a default value is provided
            rules={{
              required: "هذا الحقل مطلوب",
            }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={craftsAndIndustries}
                getOptionLabel={(option) => option.craftName}
                onChange={(event, value) => {
                  field.onChange(value);
                  setSelectedCraft(value);
                }}
                value={field.value || null} // Ensure value is always defined
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      placeholder: "",
                    }}
                    FormHelperTextProps={{
                      sx: {
                        textAlign: "start",
                      },
                    }}
                    error={!!errors.craftsAndIndustries}
                    helperText={
                      errors.craftsAndIndustries
                        ? errors.craftsAndIndustries.message
                        : ""
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography sx={{ fontWeight: "bold" }}>التصنيف</Typography>
          <TextField
            value={selectedCraft?.class || ""} // Ensure value is always a string
            fullWidth
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography sx={{ fontWeight: "bold" }}>الدرجة</Typography>
          <TextField
            value={selectedCraft?.grade || ""} // Ensure value is always a string
            fullWidth
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography sx={{ fontWeight: "bold" }}>الرسوم</Typography>
          <TextField
            value={selectedCraft?.priceWithCurrency || ""} // Ensure value is always a string
            fullWidth
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid sx={{ background: "#fff" }} item xs={12} md={3}>
          <Typography sx={{ fontWeight: "bold" }}>اسم المؤجر</Typography>
          <Controller
            name="renterName"
            control={control}
            defaultValue=""
            rules={{
              required: false,
            }}
            render={({ field }) => (
              <TextField {...field} fullWidth variant="outlined" />
            )}
          />
        </Grid>
        <Grid sx={{ background: "#fff" }} item xs={12} md={3}>
          <Typography sx={{ fontWeight: "bold" }}>رقم وثيقة المؤجر</Typography>
          <Controller
            name="renterDocument"
            control={control}
            rules={{
              required: false,
              pattern: {
                value: /^[0-9]*$/,
                message: "يجب أن يكون رقم الوثيقة أرقام فقط",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                variant="outlined"
                FormHelperTextProps={{
                  sx: {
                    textAlign: "start",
                  },
                }}
                error={!!errors.renterDocument}
                helperText={
                  errors.renterDocument ? errors.renterDocument.message : ""
                }
              />
            )}
          />
        </Grid>
        <Grid sx={{ background: "#fff" }} item xs={12} md={3}>
          <Typography sx={{ fontWeight: "bold" }}>الاسم التجاري</Typography>
          <Controller
            name="tradeName"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field }) => (
              <TextField {...field} fullWidth variant="outlined" />
            )}
          />
        </Grid>
        <Grid sx={{ background: "#fff" }} item xs={12} md={3}>
          <Typography sx={{ fontWeight: "bold" }}>
            رقم الرخصة التجارية
          </Typography>
          <Controller
            name="businessLicenseNumber"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field }) => (
              <TextField {...field} fullWidth variant="outlined" />
            )}
          />
        </Grid>
        <Grid sx={{ background: "#fff" }} item xs={12} md={12}>
          <Typography sx={{ fontWeight: "bold" }}>الملاحظات</Typography>
          <Controller
            name="craftNotes"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field }) => (
              <TextField {...field} fullWidth variant="outlined" />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CraftmentForm;
