import { useLocation, useNavigate } from "react-router-dom";
import { Box, Divider, Grid, Paper, Stack, Typography, IconButton } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import React, { useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const MobileServiceGuideDetailsItem = () => {
  const location = useLocation();
  if (!location.state) {
    nav("/services-guide");
  }
  const currencyName = location.state?.currency?.[0].name;
  const {
    applicationFee,
    compilationTime,
    isPdfExist,
    requiredAttachments,
    pdfPath,
  } = location.state;
  const nav = useNavigate();

  const openPdf = () => {
    window.open(pdfPath, "_blank");
  };

  return (
    <Paper sx={{ p: 2 }} square>
      <Stack>
        <Stack gap={2} justifyConent="end">
          <Typography sx={{ fontWeight: "bold" }}>
            رسوم الطلب: {applicationFee} {currencyName}{" "}
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            {`الوقت اللازم لإنجاز الطلب: ${compilationTime ? `${compilationTime } ايام`: ""}`}
          </Typography>
          <Box
            display={"flex"}
            alignItems={"start"}
            gap={1}
            sx={{
              "& .MuiButtonBase-root": {
                padding: 0,
              },
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>دليل الخدمة :</Typography>
            <IconButton
              color={"primary"}
              disabled={!isPdfExist}
              onClick={openPdf}
            >
              <PictureAsPdfIcon sx={{ padding: 0 }} />
            </IconButton>
          </Box>
        </Stack>

        {requiredAttachments.map((t, i) => {
          return (
            <Paper sx={{ width: "100%", mt: 2, p: 3 }} elevation={5}>
              <Stack sx={{ width: "100%", gap: 2 }}>
                <Box gap={2} display="flex">
                  <Typography>نوع&nbsp;المرفق:</Typography>
                  <Typography>{t.attachmentType}</Typography>
                </Box>
                <Divider />
                <Box gap={2} display="flex">
                  <Typography>اجباري</Typography>
                  {t.isMandatory && (
                    <CheckCircleOutlineOutlinedIcon htmlColor="#32CE37" />
                  )}
                </Box>
                <Divider />

                <Box gap={2} display="flex">
                  <Typography>اختياري</Typography>
                  {!t.isMandatory && (
                    <CheckCircleOutlineOutlinedIcon htmlColor="#32CE37" />
                  )}
                </Box>
              </Stack>
            </Paper>
          );
        })}
      </Stack>
    </Paper>
  );
};
export default MobileServiceGuideDetailsItem;
