import React, { useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import SignupForm from "../Components/SignupForm";
import { isMobile } from "react-device-detect";

const steps = ["المعلومات الاساسية", "حساب المواطن", "تاكيد التسجيل"];

const Signup = () => {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ width: "100%" }}>
        <Stepper
          activeStep={activeStep}
          orientation={isMobile ? "vertical" : "horizontal"}
          sx={
            isMobile
              ? {
                  "& .MuiStepConnector-root": {
                    marginLeft: "auto",
                    marginRight: "8%",
                  },
                }
              : {
                "& .MuiStepLabel-label": {
                  marginRight: 1
                },
                "& .MuiStepLabel-iconContainer": {
                  padding: 0
                }
              }
          }
        >
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ my: 2 }}>
          <SignupForm
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            stepsLength={steps.length}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Signup;
