import { Box, Typography, Grid, Stack, TextField, Button } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { UserDetailsContext } from "../../Context/userDetailsContext";
import { useForm, Controller } from "react-hook-form";
import { isMobile } from "react-device-detect";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import axios from "axios";
import { LoadingButton } from "@mui/lab";

const PersonalInfo = () => {
  const [adjustFields, setAdjustField] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const { userDetails, setUserDetails } = useContext(UserDetailsContext);
  const [isLoading, setIsloading] = useState(false);
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    trigger,
    watch,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const watchedValues = watch();
  const userInfo = userDetails?.citizen;

  useEffect(() => {
    setFormValuesToDefault();
  }, [userInfo, setValue]);

  useEffect(() => {
    const checkChanges = () => {
      setHasChanges(
        watchedValues.mainMobileNumber !== initialValues.mainMobileNumber ||
          watchedValues.user_email !== initialValues.user_email ||
          !dayjs(watchedValues.birthDate).isSame(
            initialValues.birthDate,
            "day"
          ) ||
          watchedValues.address !== initialValues.address
      );
    };
    checkChanges();
  }, [watchedValues, initialValues]);

  const setFormValuesToDefault = () => {
    if (userInfo) {
      const initialFormValues = {
        mainMobileNumber: userInfo.phone1,
        user_email: userInfo.email,
        birthDate: dayjs(userInfo.birthdate),
        address: userInfo.address,
      };
      setValue("mainMobileNumber", initialFormValues.mainMobileNumber);
      setValue("user_email", initialFormValues.user_email);
      setValue("birthDate", initialFormValues.birthDate);
      setValue("address", initialFormValues.address);
      setInitialValues(initialFormValues);
      setHasChanges(false);
    }
  };

  const onAdjustFieldsClick = () => {
    setAdjustField((prev) => !prev);
  };
  const onCancelChanges = () => {
    setFormValuesToDefault();
    clearErrors();
    setAdjustField(false);
  };
  const onSubmit = async (data) => {
    try {
      setIsloading(true);
      const newUserData = {
        phone1: data.mainMobileNumber,
        email: data.user_email,
        birthdate: data.birthDate.toISOString(),
        address: data.address,
      };
      const response = await axios.put("user", newUserData);
      setIsloading(false);
      setAdjustField(false);
      setUserDetails({ ...response, isAuthenticated: true });
    } catch (error) {
      setIsloading(false);
    }
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Stack gap={2}>
            <Grid sx={{ width: "100%" }} spacing={2} container>
              <Grid item md={5} xs={12}>
                <Typography
                  sx={{ fontWeight: "bold", marginBottom: 1 }}
                  color={"primary"}
                >
                  الاسم الرباعي
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    style: {},
                  }}
                  InputProps={{
                    disableUnderline: true,
                    placeholder: "",
                  }}
                  value={userInfo?.fullName}
                  disabled
                />
              </Grid>
              <Grid item md={5} xs={12}>
                <Typography
                  sx={{ fontWeight: "bold", marginBottom: 1 }}
                  color={"primary"}
                >
                  رقم الوثيقة
                </Typography>
                <TextField
                  disabled
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    style: {},
                  }}
                  InputProps={{
                    disableUnderline: true,
                    placeholder: "",
                  }}
                  value={userInfo.documentNumber}
                />
              </Grid>
            </Grid>
            <Grid sx={{ width: "100%" }} spacing={2} container>
              <Grid item md={5} xs={12}>
                <Typography
                  sx={{ fontWeight: "bold", marginBottom: 1 }}
                  color={"primary"}
                >
                  رقم الموبايل
                </Typography>
                <Controller
                  name="mainMobileNumber"
                  control={control}
                  rules={{
                    required: "هذا الحقل مطلوب",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "الرجاء إدخال أرقام فقط",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={!adjustFields}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        style: {},
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.mainMobileNumber}
                      helperText={
                        errors.mainMobileNumber
                          ? errors.mainMobileNumber.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={5} xs={12}>
                <Typography
                  sx={{ fontWeight: "bold", marginBottom: 1 }}
                  color={"primary"}
                >
                  البريد الالكتروني
                </Typography>
                <Controller
                  name="user_email"
                  control={control}
                  rules={{
                    required: false,
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "الرجاء إدخال بريد إلكتروني صحيح",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="email"
                      disabled={!adjustFields}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                        placeholder: "",
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      autoComplete="off"
                      error={!!errors.user_email}
                      helperText={
                        errors.user_email ? errors.user_email.message : ""
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid sx={{ width: "100%" }} spacing={2} container>
              <Grid item md={5} xs={12}>
                <Typography
                  sx={{ fontWeight: "bold", marginBottom: 1 }}
                  color={"primary"}
                >
                  تاريخ الميلاد
                </Typography>
                <Controller
                  name="birthDate"
                  control={control}
                  rules={{
                    required: "هذا الحقل مطلوب",
                    validate: (value) => {
                      const birthDate = dayjs(value);
                      const today = dayjs();
                      const age = today.diff(birthDate, "year");
                      return age >= 16 || "يجب أن يكون العمر أكبر من 16 عامًا";
                    },
                  }}
                  render={({ field }) => (
                    <Stack>
                      <DatePicker
                        sx={{
                          "& .MuiInputBase-root": {
                            paddingRight: "4px",
                          },
                          "& .MuiInputAdornment-root": {
                            marginLeft: 0,
                          },
                          "& input": {
                            border: "none !important",
                            paddingLeft: 0,
                          },
                          "& button": {
                            padding: 0,
                          },
                          ...(errors.birthDate && {
                            "& fieldset": {
                              borderColor: "#d32f2f",
                            },
                          }),
                        }}
                        disabled={!adjustFields}
                        {...field}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            error={!!errors.birthDate}
                          />
                        )}
                      />
                      {errors.birthDate && (
                        <Typography variant="caption" color="error">
                          {errors.birthDate.message}
                        </Typography>
                      )}
                    </Stack>
                  )}
                />
              </Grid>
              <Grid item md={5} xs={12}>
                <Typography
                  sx={{ fontWeight: "bold", marginBottom: 1 }}
                  color={"primary"}
                >
                  العنوان
                </Typography>
                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: "هذا الحقل مطلوب",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={!adjustFields}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        style: {},
                      }}
                      InputProps={{
                        disableUnderline: true,
                        placeholder: "",
                      }}
                      minRows={1}
                      maxRows={10}
                      multiline
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "start",
                        },
                      }}
                      error={!!errors.address}
                      helperText={errors.address ? errors.address.message : ""}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Stack>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pt: 5,
              gap: 2,
              justifyContent: "end",
            }}
          >
            <>
              {!adjustFields ? (
                <Button
                  sx={{ width: "20%", alignSelf: "center" }}
                  variant="contained"
                  onClick={onAdjustFieldsClick}
                >
                  <Typography>تعديل</Typography>
                </Button>
              ) : (
                <>
                  <Button
                    sx={{ width: "20%", alignSelf: "center" }}
                    variant="contained"
                    onClick={onCancelChanges}
                  >
                    <Typography>الغاء</Typography>
                  </Button>

                  <LoadingButton
                    type="submit"
                    sx={{ width: "20%", alignSelf: "center" }}
                    variant="contained"
                    disabled={!hasChanges}
                    loading={isLoading}
                  >
                    <Typography>حفظ</Typography>
                  </LoadingButton>
                </>
              )}
            </>
          </Box>
        </form>
      </LocalizationProvider>
    </>
  );
};

export default PersonalInfo;
